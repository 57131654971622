import Typography from "@material-ui/core/Typography";
import React from "react";

export default props => (
  <Typography
    variant="h6"
    gutterBottom
    style={{
      padding: "0",
      paddingBottom: "10px",
      marginBottom: "0",
      ...props.style
    }}
  >
    {props.children}
  </Typography>
);
