import { createStore, combineReducers } from "redux";

import createHttp from "../io/http";

import filterContext from "./reducers/filterContext";
import navBar from "./reducers/navBar";
import auth from "./reducers/auth";

// Create a Redux store holding the state of your app.
// Its API is { subscribe, dispatch, getState }.
let reducer = combineReducers({});
let store = createStore(reducer);

// Http needs to store in order to redirect logged out users if requests unauthorized
function io(state = { http: createHttp(store) }, action) {
  return state;
}

store.replaceReducer(combineReducers({ auth, io, navBar, filterContext }));

// You can use subscribe() to update the UI in response to state changes.
// Normally you'd use a view binding library (e.g. React Redux) rather than subscribe() directly.
// However it can also be handy to persist the current state in the localStorage.

store.subscribe(() => console.log(store.getState()));

export default store;
