import React, { Component } from "react";

import EditListingForm from "./EditListingForm";
import { connect } from "react-redux";

class EditListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listingId: props.match.params.listingId
    };
  }

  render() {
    return (
      <div>
        {this.state.listing === undefined ? (
          <span>Loading...</span>
        ) : (
          <div>
            <h1>Edit {this.state.listing.name}</h1>
            <EditListingForm
              initialListing={this.state.listing}
              onSubmitHandler={this.saveListing.bind(this)}
            />
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    const setState = this.setState.bind(this);
    this.getListing().then(listing => {
      setState({ listing });
    });
  }

  getListing() {
    const { listingId } = this.state;

    return this.props
      .getJson("/v7/listing/" + listingId)
      .then(response => response.json);
  }

  saveListing(updatedListing) {
    this.setState(
      { listing: { ...this.state.listing, ...updatedListing } },
      () => {
        this.postListing();
      }
    );
  }

  postListing() {
    const { listingId } = this.state;

    this.props.postJson("/v7/listing/" + listingId, this.state.listing);
  }
}

const mapStateToProps = state => {
  return {
    postJson: state.io.http.postJson,
    getJson: state.io.http.getJson
  };
};

export default connect(mapStateToProps)(EditListing);
