import React from "react";
import { Paper } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import IconButton from "../UIComponents/Button/IconButton";
import RateReviewIcon from "@material-ui/icons/RateReview";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ReachPaper from "../UIComponents/ReachPaper";

export const ReachRating = ({ rating }) => {
  return (
    <div>
      {rating.name}:<br />
      {rating.outOf === 1 &&
        rating.rating !== undefined &&
        (rating.rating === 0 ? <ThumbDownIcon /> : <ThumbUpIcon />)}
      {rating.outOf !== 1 && rating.rating !== undefined && (
        <Rating
          name="disabled"
          value={rating.rating}
          max={rating.outOf}
          readOnly
        />
      )}
      {rating.outOf !== 1 && rating.rating === undefined && (
        <Rating name="read-only" value={null} max={rating.outOf} readOnly />
      )}
    </div>
  );
};

function FeedbackCard(props) {
  const { feedback } = props;

  return (
    <ReachPaper style={{ marginBottom: "5px" }}>
      <b>
        {feedback.email || "Anonymous"} on {feedback.timestamp}:
      </b>
      {feedback.ratings.map(feedbackRating => {
        return <ReachRating rating={feedbackRating} />;
      })}
      <div>
        <i>{feedback.message}</i>
      </div>
      {props.showDetailLink && (
        <IconButton
          variant="contained"
          href={"/feedbackDetails/" + feedback.id}
          type="button"
          style={{ "margin-top": "10px" }}
          icon={<RateReviewIcon />}
        >
          Details
        </IconButton>
      )}
    </ReachPaper>
  );
}

export default FeedbackCard;
