import React, { Component, Fragment, useState } from "react";

// Import React Table
import "react-table/react-table.css";

import "./CRMLeadsController.css";

import { Link } from "react-router-dom";
import { prettyDate, prettyTime } from "../../util/prettyDate";
import MaterialTable from "material-table";

import FormType from "./FormType";
import ProviderIcon from "../Provider/ProviderIcon";
import DraftsIcon from "@material-ui/icons/Drafts";
import SectionHeader from "../UIComponents/Typography/SectionHeader";
import tableIcons from "../UIComponents/Table/tableIcons";
import ReachPaper from "../UIComponents/ReachPaper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  Favorite,
  FavoriteBorderOutlined,
  ThumbUp,
  ThumbUpOutlined,
  StarBorderOutlined,
  Star,
  FlagOutlined,
  Flag
} from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";
import { setUserSessionInfo } from "../../state/actionCreators/counter";
import { connect } from "react-redux";

const defaultTitle = "Leads";

const YellowThumb = withStyles({
  root: {
    color: "rgb(252, 180, 0)",
    "&$checked": {
      color: "rgb(252, 180, 0)"
    }
  },
  checked: {}
})(props => (
  <Checkbox
    icon={<ThumbUpOutlined />}
    checkedIcon={<ThumbUp />}
    name="checkedH"
    color="default"
    {...props}
  />
));

const GreenHeart = withStyles({
  root: {
    color: "rgb(32, 201, 51)",
    "&$checked": {
      color: "rgb(32, 201, 51)"
    }
  },
  checked: {}
})(props => (
  <Checkbox
    icon={<FavoriteBorderOutlined />}
    checkedIcon={<Favorite />}
    name="checkedH"
    color="default"
    {...props}
  />
));

const RedStar = withStyles({
  root: {
    color: "rgb(248, 43, 96);",
    "&$checked": {
      color: "rgb(248, 43, 96);"
    }
  },
  checked: {}
})(props => (
  <Checkbox
    icon={<StarBorderOutlined />}
    checkedIcon={<Star />}
    name="checkedH"
    color="default"
    {...props}
  />
));

const PurpleFlag = withStyles({
  root: {
    color: "rgb(139, 70, 255)",
    "&$checked": {
      color: "rgb(139, 70, 255)"
    }
  },
  checked: {}
})(props => (
  <Checkbox
    icon={<FlagOutlined />}
    checkedIcon={<Flag />}
    name="checkedH"
    color="default"
    {...props}
  />
));

function LeadsTable(props) {
  const { filters, style } = props;

  const [leads, setLeads] = useState(props.leads);

  const title = props.title || defaultTitle;

  const markQualified = leadId => () => {
    props.post("/v7/lead/" + leadId + "/qualified");

    const updated = leads.map(lead => {
      return lead.id === leadId
        ? { ...lead, crm: { ...lead.crm, qualified: true } }
        : lead;
    });

    setLeads(updated);
  };

  const markContacted = leadId => () => {
    props.post("/v7/lead/" + leadId + "/contacted");

    const updated = leads.map(lead => {
      return lead.id === leadId
        ? { ...lead, crm: { ...lead.crm, contacted: true } }
        : lead;
    });

    setLeads(updated);
  };

  const markPresented = leadId => () => {
    props.post("/v7/lead/" + leadId + "/presented");

    const updated = leads.map(lead => {
      return lead.id === leadId
        ? { ...lead, crm: { ...lead.crm, presented: true } }
        : lead;
    });

    setLeads(updated);
  };

  const markBecameClient = leadId => () => {
    props.post("/v7/lead/" + leadId + "/becameClient");

    const updated = leads.map(lead => {
      return lead.id === leadId
        ? { ...lead, crm: { ...lead.crm, becameClient: true } }
        : lead;
    });

    setLeads(updated);
  };

  const columns = filters => {
    return [
      {
        title: "",
        render: lead => (
          <Link to={"/lead/" + lead.id}>
            <FormType
              formType={lead.context.formType}
              style={{ fontSize: "1.5em" }}
            />
          </Link>
        ),
        cellStyle: {
          paddingRight: 0
        },
        headerStyle: {
          paddingRight: 0
        }
      },
      {
        title: "Contact",
        render: row => {
          return (
            <Fragment>
              <Link to={"/lead/" + row.id}>{row.details.name}</Link>
              {!!row.details.phone && row.details.phone !== "Unspecified" && (
                <Fragment>
                  <br />
                  <a
                    className="secondaryLink"
                    href={"tel:" + row.details.phone}
                  >
                    {row.details.phone}
                  </a>
                </Fragment>
              )}
              {!!row.details.email && (
                <Fragment>
                  <br />
                  <a
                    className="secondaryLink"
                    href={"mailto:" + row.details.email}
                  >
                    {row.details.email}
                  </a>
                </Fragment>
              )}
            </Fragment>
          );
        }
      },
      {
        title: "Date",
        render: lead => (
          <Fragment>
            {prettyDate(lead.timestamp)}
            <br />
            <span className="secondaryText">{prettyTime(lead.timestamp)}</span>
          </Fragment>
        )
      },
      {
        id: "referrer",
        title: "Source",
        render: lead => {
          const provider = lead.utm.source || lead.context.referrer || "";
          // If the referer is a url, remove the query string
          const split = provider.split("?");
          const firstPart = split.length > 0 ? split[0] : "";
          return <ProviderIcon providerName={firstPart} />;
        }
      },
      {
        id: "formName",
        title: "Form",
        render: lead => <Fragment>{lead.context.formName}</Fragment>
      }
    ]
      .concat(
        filters.businessId.length === 1 // Include client name if none or more than 1 selected
          ? []
          : [
              {
                title: "Client",
                field: "businessName"
              }
            ]
      )
      .concat([
        {
          title: "Opened",
          render: lead =>
            !!lead.opened && <DraftsIcon style={{ fontSize: "1.7em" }} />
        }
      ])
      .concat([
        {
          title: "Qualified",
          render: lead => (
            <YellowThumb
              checked={!!lead.crm.qualified}
              onChange={markQualified(lead.id)}
            />
          )
        },
        {
          title: "Contacted",
          render: lead => (
            <RedStar
              checked={!!lead.crm.contacted}
              onChange={markContacted(lead.id)}
            />
          )
        },
        {
          title: "Presented",
          render: lead => (
            <PurpleFlag
              checked={!!lead.crm.presented}
              onChange={markPresented(lead.id)}
            />
          )
        },
        {
          title: "Client",
          render: lead => (
            <GreenHeart
              checked={!!lead.crm.becameClient}
              onChange={markBecameClient(lead.id)}
            />
          )
        }
      ]);
  };

  return (
    <div style={{ maxWidth: "100%", ...style }}>
      <MaterialTable
        components={{
          Container: props => <ReachPaper {...props} />
        }}
        columns={columns(filters)}
        data={leads}
        search={false}
        options={{
          pageSize: leads.length === 0 ? 50 : Math.min(leads.length, 50),
          pageSizeOptions: [50, 100, 200]
        }}
        classes={{ toolbar: { title: "myTitleClass" } }}
        title={<SectionHeader>{title}</SectionHeader>}
        icons={tableIcons}
      />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    post: state.io.http.post
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LeadsTable);
