import React, { Fragment, useState } from "react";
import Button from "@material-ui/core/Button";

function LeadDelivery(delivery) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div style={{ marginBottom: "15px" }}>
      <b>
        {delivery.deliveryType}:{" "}
        {delivery.recipient.email || delivery.recipient.sms}
      </b>
      <br />
      {delivery.deliveryType === "Email" && (
        <Fragment>
          Opened:{" "}
          {!!delivery.opened
            ? new Date(parseInt(delivery.opened)).toLocaleString()
            : "Unopened"}
          <br />
        </Fragment>
      )}
      {delivery.subject && (
        <Fragment>
          Subject: {delivery.subject}
          <br />
        </Fragment>
      )}
      {expanded && (
        <Fragment>
          Message:{" "}
          <div
            dangerouslySetInnerHTML={{
              __html: delivery.message.replace(/\n/g, "<br />")
            }}
          ></div>
        </Fragment>
      )}
      <Button onClick={() => setExpanded(!expanded)}>
        {expanded ? "Collapse Message" : "Show Message"}
      </Button>
    </div>
  );
}

export default LeadDelivery;
