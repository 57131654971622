import React, { useState, useEffect } from "react";
import ProviderIcon from "../Provider/ProviderIcon";
import MaterialTable from "material-table";
import { connect } from "react-redux";

import { parse } from "qs";
import { ProviderButton } from "../Provider/providerColors";
import tableIcons from "../UIComponents/Table/tableIcons";
import ReachPaper from "../UIComponents/ReachPaper";

const columns = [
  {
    title: "Network",
    render: row => <ProviderIcon providerName={row.provider} />
  },
  {
    title: "Account Name",
    field: "name"
  },
  {
    title: "Expiration Date",
    render: row =>
      !!row.expirationDate
        ? new Date(row.expirationDate).toLocaleDateString()
        : ""
  }
  // { TODO
  //   title: "Date Added",
  //   field: "timestamp"
  // }
];

let handledOAuthToken = false;

function Settings(props) {
  const [oAuthUrls, setOAuthurls] = useState([]);
  const [credentials, setCredentials] = useState([]);

  useEffect(() => {
    props.getJson("/v7/oauth/urls").then(response => {
      setOAuthurls(response.json.oAuthUrls);
    });
    props.getJson("/v7/oauth/credentials").then(response => {
      setCredentials(response.json.credentials);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function processOauthTokens() {
    handledOAuthToken = true;
    const splitQueryString = window.location.href.split("?");
    const queryString =
      splitQueryString.length > 0 ? parse(splitQueryString[1]) : false;
    if (
      !!queryString &&
      !!queryString.oauth_verifier &&
      !!queryString.oauth_token
    ) {
      // Twitter
      props
        .get(
          "/v7/oauth/twitter?oauth_verifier=" +
            queryString.oauth_verifier +
            "&oauth_token=" +
            queryString.oauth_token
        )
        .then(response => {
          props.getJson("/v7/oauth/credentials").then(response => {
            setCredentials(response.json.credentials);
          });
        });
    } else if (!!queryString && !!queryString.code && !!queryString.scope) {
      // Google
      props
        .get(
          "/v7/oauth/google?code=" +
            queryString.code +
            "&scope=" +
            queryString.scope
        )
        .then(response => {
          props.getJson("/v7/oauth/credentials").then(response => {
            setCredentials(response.json.credentials);
          });
        });
    } else if (!!queryString && !!queryString.code && !queryString.scope) {
      if (window.location.href.includes("provider=LinkedIn")) {
        // LinkedIn
        props
          .get("/v7/oauth/linkedin?code=" + queryString.code)
          .then(response => {
            props.getJson("/v7/oauth/credentials").then(response => {
              setCredentials(response.json.credentials);
            });
          });
      } else {
        // Facebook
        props
          .get("/v7/oauth/facebook?code=" + queryString.code)
          .then(response => {
            props.getJson("/v7/oauth/credentials").then(response => {
              setCredentials(response.json.credentials);
            });
          });
      }
    }
  }
  if (!handledOAuthToken) {
    processOauthTokens();
  }

  function deleteCredential(credentialId) {
    props.delete("/v7/oauth/credentials/" + credentialId).then(response => {
      props.getJson("/v7/oauth/credentials").then(response => {
        setCredentials(response.json.credentials);
      });
    });
  }

  return (
    <div>
      <h1>Settings</h1>
      {/*<p>You clicked {count} times</p>*/}
      {/*<button onClick={() => setCount(count + 1)}>Click me</button>*/}

      {oAuthUrls.map((oAuthUrl, index) => {
        return (
          <ProviderButton
            key={index}
            variant="contained"
            provider={oAuthUrl.provider}
            style={{
              textTransform: "none",
              marginRight: "10px",
              marginBottom: "10px"
            }}
            href={oAuthUrl.url}
            type="submit"
          >
            Add Account
          </ProviderButton>
        );
      })}
      <MaterialTable
        components={{
          Container: props => <ReachPaper {...props} />
        }}
        columns={columns}
        data={credentials}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 50, 100]
        }}
        actions={[
          rowData => ({
            iconProps: (a, b) => {},
            icon: (a, b) => {
              return <tableIcons.DeleteOutline />;
            },
            tooltip:
              "Logout and remove associated advertising campaigns from the dashboard.",
            onClick: (event, rowData) => deleteCredential(rowData.id)
          })
        ]}
        title="Network Accounts"
        icons={tableIcons}
      />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson,
    get: state.io.http.get,
    delete: state.io.http.delete
  };
};

export default connect(mapStateToProps)(Settings);
