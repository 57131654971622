import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import ReviewCard from "./ReviewCard";

function Reviews(props) {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    props.getJson("/v7/websiteReviews").then(response => {
      setReviews(response.json);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {reviews.map(review => {
        return <ReviewCard review={review} showDetailLink={true} />;
      })}
    </Fragment>
  );
}

const mapStateToProps = state => ({
  getJson: state.io.http.getJson
});

export default connect(mapStateToProps)(Reviews);
