import React, {useState} from "react";
import {Map} from "pigeon-maps";

export default function ReachMap({lat, lng}) {
    const [center, setCenter] = useState([lat, lng])
    const [zoom, setZoom] = useState(11)
    return (
        <Map
            height={300}
            center={center}
            zoom={zoom}
            onBoundsChanged={({ center, zoom }) => {
                setCenter(center)
                setZoom(zoom)
            }}
        />
    )
}
