import React, { Component, Fragment } from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const columns = [
  {
    Header: "Name",
    accessor: "name",
    Cell: row => <Link to={"/adCampaign/" + row.original.id}>{row.value}</Link>
  },
  {
    Header: "Provider",
    accessor: "provider"
  },
  {
    Header: "# Leads",
    accessor: "leadCount"
  },
  {
    Header: "Cost Per Lead",
    accessor: "averageCostPerLead"
  },
  {
    Header: "Avg. Lead Value",
    accessor: "averageLeadValue"
  }
];

class AdCampaigns extends Component {
  state = {
    adCampaigns: null
  };

  componentDidMount() {
    const setState = this.setState.bind(this);
    this.props.getJson("/v7/adCampaigns").then(response => {
      setState(response.json);
    });
  }

  render() {
    return (
      <Fragment>
        <h1>Ad Campaigns</h1>

        {this.state.adCampaigns && (
          <ReactTable
            data={this.state.adCampaigns}
            columns={columns}
            defaultPageSize={10}
            className="-striped -highlight"
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson
  };
};

export default connect(mapStateToProps)(AdCampaigns);
