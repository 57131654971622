import React, { Fragment } from "react";

// Import React Table
import "react-table/react-table.css";

import MaterialTable from "material-table";
import SectionHeader from "../UIComponents/Typography/SectionHeader";
import tableIcons from "../UIComponents/Table/tableIcons";
import { formatDollars } from "../../util/formatCurrency";
import { prettyDate, prettyTime } from "../../util/prettyDate";
import ReachPaper from "../UIComponents/ReachPaper";

const columns = [
  {
    title: "Item",
    render: purchaseItem => {
      const maybePurchaseItemImage =
        purchaseItem.itemImageUrl.length > 0 &&
        purchaseItem.itemImageUrl[0] !== ""
          ? purchaseItem.itemImageUrl[0]
          : "https://place-hold.it/75x50?text=";

      return (
        <div>
          {maybePurchaseItemImage && (
            <img
              alt={maybePurchaseItemImage.name}
              src={maybePurchaseItemImage}
              style={{ height: "50px" }}
            />
          )}
          {maybePurchaseItemImage.name}
        </div>
      );
    },
    cellStyle: {
      paddingRight: 0
    },
    headerStyle: {
      paddingRight: 0
    }
  },
  {
    title: "",
    render: purchaseItem => (
      <Fragment>
        <div>{purchaseItem.itemName}</div>
        {!!purchaseItem.itemDescription && (
          <div>{purchaseItem.itemDescription}</div>
        )}
      </Fragment>
    )
  },
  {
    title: "Amount",
    render: purchaseItem => formatDollars(purchaseItem.itemValueAmount / 100)
  },
  {
    title: "Date",
    render: purchaseItem => (
      <Fragment>
        {prettyDate(purchaseItem.timestamp)}
        <br />
        <span className="secondaryText">
          {prettyTime(purchaseItem.timestamp)}
        </span>
      </Fragment>
    )
  }
];

function Purchases(props) {
  const { purchaseItems } = props;

  return (
    <MaterialTable
      components={{
        Container: props => <ReachPaper {...props} />
      }}
      columns={columns}
      data={purchaseItems}
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 50, 100, 200],
        ...props.options
      }}
      classes={{ toolbar: { title: "myTitleClass" } }}
      title={<SectionHeader>Purchases</SectionHeader>}
      icons={tableIcons}
    />
  );
}

export default Purchases;
