import React from "react";
import FilterPanel from "../UIComponents/FilterContext/FilterPanel";

function Home(props) {
  return (
    <div>
      <FilterPanel />
    </div>
  );
}

export default Home;
