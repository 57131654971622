import React, { useEffect, useState } from "react";
import ReviewCard from "./ReviewCard";
import EventsTimeline from "../EventTracking/EventsTimeline";
import { connect } from "react-redux";

function Review(props) {
  const { reviewId } = props.match.params;
  const [review, setReview] = useState(null);
  const [reviewEvents, setReviewEvents] = useState(null);

  useEffect(() => {
    props.getJson("/v7/websiteReview/" + reviewId).then(response => {
      setReview(response.json);
    });
    props
      .getJson("/v7/websiteReview/" + reviewId + "/events")
      .then(response => {
        setReviewEvents(response.json);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h1>Review Details</h1>
      {review && <ReviewCard review={review} showDetailLink={false} />}
      {reviewEvents && <EventsTimeline events={reviewEvents.events} />}
    </div>
  );
}

const mapStateToProps = state => ({
  getJson: state.io.http.getJson
});

export default connect(mapStateToProps)(Review);
