import React, { Fragment, useEffect, useState } from "react";

import "./Website.css";
import { Card } from "@material-ui/core";
import SectionHeader from "../UIComponents/Typography/SectionHeader";
import { Link } from "react-router-dom";
import FilterPanel from "../UIComponents/FilterContext/FilterPanel";
import { connect } from "react-redux";
import reachStringify from "../../util/stringify";
import ReachPaper from "../UIComponents/ReachPaper";
import EmailOutlinedIcon from "@material-ui/core/SvgIcon/SvgIcon";
import IconButton from "../UIComponents/Button/IconButton";
import ListIcon from "@material-ui/icons/List";

function WebsiteCard(props) {
  const website = props.website;

  return (
    <ReachPaper style={{ marginBottom: "20px" }}>
      <SectionHeader>
        <Link to={"/website/" + website.id}>{website.name}</Link>
      </SectionHeader>
      <img
        src={website.screenshot || "https://place-hold.it/200x200?text="}
        className={"reach-website-card"}
        alt={website.name + " Screenshot"}
      />
      <br />
      <a href={website.url}>{website.url}</a>
      <br />
      <IconButton
        variant="contained"
        href={"/website/" + website.id + "/events"}
        type="button"
        icon={<ListIcon />}
      >
        Live Events
      </IconButton>
      <IconButton
        variant="contained"
        href={"/website/" + website.id + "/eventSessions"}
        style={{ marginLeft: "20px" }}
        type="button"
        icon={<ListIcon />}
      >
        Most Recent Events
      </IconButton>
      {/*Visits: {props.pageVisits}*/}
      {/*<br />*/}
      {/*Average Visit Time: 10s*/}
      {/*<br />*/}
      {/*Conversion Rate: 17%*/}
      {/*<br />*/}
    </ReachPaper>
  );
}

function Websites(props) {
  const [websites, setWebsites] = useState([]);

  const queryString = reachStringify(props.filters);

  useEffect(() => {
    props.getJson("/v7/websites?" + queryString).then(response => {
      setWebsites(response.json.websites);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <FilterPanel />
      {websites.map((website, index) => (
        <WebsiteCard key={index} website={website} />
      ))}
    </Fragment>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson,
    filters: state.filterContext.filters
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Websites);
