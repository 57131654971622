import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import FeedbackCard from "./FeedbackCard";

function Feedbacks(props) {
  const [feedbacks, setFeedbacks] = useState([]);

  useEffect(() => {
    props.getJson("/v7/feedback").then(response => {
      setFeedbacks(response.json);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {feedbacks.map(feedback => {
        return <FeedbackCard feedback={feedback} showDetailLink={true} />;
      })}
    </Fragment>
  );
}

const mapStateToProps = state => ({
  getJson: state.io.http.getJson
});

export default connect(mapStateToProps)(Feedbacks);
