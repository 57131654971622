import React from "react";
import PropTypes from "prop-types";
import { connect, Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import EditListing from "./components/Listings/EditListing.js";
import ListListings from "./components/Listings/ListListings.js";
import {
  expandNavbar,
  toggleNavbar,
  loadFilterContext,
  setUserSessionInfo
} from "./state/actionCreators/counter";
import CRMLeadsController from "./components/CRM/CRMLeadsController";
import Performance from "./components/Performance/Performance";
import Businesses from "./components/Agency/Businesses";
import BusinessStatistics from "./components/Agency/BusinessStatistics";
import Website from "./components/Agency/Website";

import "./App.css";
import Login from "./components/Login/Login";
import Lead from "./components/CRM/Lead";
import AdCampaign from "./components/AdCampaign/AdCampaign";
import AdCampaigns from "./components/AdCampaign/AdCampaigns";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import NavigationSideBar from "./components/Navigation/NavigationSideBar";

import { ThemeProvider } from "styled-components";
import { createMuiTheme } from "@material-ui/core";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";
import Budget from "./components/Budget/Budget";
import Settings from "./components/Settings/Settings";
import moment from "moment";
import ProviderAdCampaign from "./components/AdCampaign/ProviderAdCampaign";
import {
  facebookBlue,
  googleRed,
  twitterBlue
} from "./components/Provider/providerColors";
import Footer from "./components/Footer/Footer";
import withStyles from "@material-ui/core/styles/withStyles";
import Websites from "./components/Agency/Websites";
import EventController from "./components/EventTracking/EventController";
import BusinessCreate from "./components/Agency/BusinessCreate";
import BusinessUpdate from "./components/Agency/BusinessUpdate";
import BusinessWebsiteCreate from "./components/Agency/BusinessWebsiteCreate";
import Agencies from "./components/Agency/Agencies";
import AgencyUpdate from "./components/Agency/AgencyUpdate";
import AgencyCreate from "./components/Agency/AgencyCreate";
import Users from "./components/Agency/Users/Users";
import UserUpdate from "./components/Agency/Users/UserUpdate";
import UserCreate from "./components/Agency/Users/UserCreate";
import Reviews from "./components/Reviews/Reviews";
import Review from "./components/Reviews/Review";
import Purchases from "./components/Purchases/Purchases";
import Home from "./components/Home/Home";
import UserLeadInvoices from "./components/Billing/UserLeadInvoices";
import Feedbacks from "./components/Feedback/Feedbacks";
import Feedback from "./components/Feedback/Feedback";
import WidgetGuide from "./components/WidgetGuide/WidgetGuide";
import EventTrackingSessions from "./components/EventTracking/EventTrackingSessions";
import EventTrackingSession from "./components/EventTracking/EventTrackingSession";

function isMobileDevice() {
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  );
}

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: green,
    Facebook: {
      main: facebookBlue
    },
    GoogleAds: googleRed,
    TwitterAds: twitterBlue
  },
  status: {
    danger: "orange"
  }
});

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    marginBottom: "20px"
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
});

class Root extends React.Component {
  componentDidMount() {
    this.impersonateUserIfTokenExists();
    this.loadLocalStorage();
  }

  impersonateUserIfTokenExists() {
    const urlParams = new URLSearchParams(window.location.search);
    const maybeImpersonateToken = urlParams.get("impersonateToken");
    if (maybeImpersonateToken !== null) {
      urlParams.delete("impersonateToken");
      this.props
        .postJson("/v7/refreshToken?token=" + maybeImpersonateToken)
        .then(response => {
          // Refresh the users token to reload for impersonated user
          // Use session storage so it only persists for one page
          sessionStorage.setItem(
            "reach.userSessionInfo",
            JSON.stringify(response.json.UserSession)
          );
          this.props.setUserSessionInfo(response.json.UserSession);
          window.location.href =
            window.location.protocol + "//" + window.location.host;
        });
    }
  }

  loadLocalStorage() {
    // Load either from session storage (if impersonating a user) or local storage
    const maybeAuthTokenStorage =
      sessionStorage.getItem("reach.userSessionInfo") ||
      localStorage.getItem("reach.userSessionInfo");
    if (
      maybeAuthTokenStorage !== null &&
      maybeAuthTokenStorage !== "undefined"
    ) {
      const maybeAuthToken = JSON.parse(maybeAuthTokenStorage);
      this.props.setUserSessionInfo(maybeAuthToken);
      console.log("Found auth token: " + JSON.stringify(maybeAuthToken));
    } else {
      console.log("Did not find auth token.");
    }

    const maybeFilterStorage = localStorage.getItem("reach.filterContext");
    if (maybeFilterStorage !== null && maybeFilterStorage !== "undefined") {
      const filterContext = JSON.parse(maybeFilterStorage);
      // Need to map string representations of dates to Date objects
      const startDate = moment(filterContext.filters.startDate).toDate();
      const endDate = moment(filterContext.filters.endDate).toDate();
      this.props.loadFilterContext({
        ...filterContext,
        filters: {
          ...filterContext.filters,
          startDate,
          endDate
        }
      });
      console.log("Found filterContext: " + JSON.stringify(filterContext));
    } else {
      console.log("Did not find filterContext.");
    }
  }

  render() {
    return (
      <Provider store={this.props.store}>
        <ThemeProvider theme={theme}>
          <Router>
            {this.props.userSessionInfo ? (
              <div
                className={
                  (this.props.navBarCollapsed ? "navBarCollapsed" : "") +
                  (isMobileDevice() ? " isMobile" : "")
                }
              >
                <NavigationSideBar />
                <div id="mainContentContainer">
                  <Route exact path="/" component={CRMLeadsController} />
                  <Route exact path="/home" component={Home} />
                  <Route
                    path="/edit/listing/:listingId"
                    component={EditListing}
                  />
                  <Route path="/listings" component={ListListings} />
                  <Route path="/performance" component={Performance} />
                  <Route path="/leads" component={CRMLeadsController} />
                  <Route path="/adCampaigns" component={AdCampaigns} />
                  <Route
                    path="/adCampaign/:adCampaignId"
                    component={AdCampaign}
                  />
                  <Route
                    path="/providerAdCampaign/:providerAdCampaignId"
                    component={ProviderAdCampaign}
                  />
                  <Route path="/lead/:leadId" component={Lead} />
                  <Route path="/purchases" component={Purchases} />
                  <Route path="/reviews" component={Reviews} />
                  <Route path="/review/:reviewId" component={Review} />
                  <Route path="/feedback" component={Feedbacks} />
                  <Route
                    path="/feedbackDetails/:feedbackId"
                    component={Feedback}
                  />
                  <Route path="/budget" component={Budget} />
                  <Route path="/websites" component={Websites} />
                  <Switch>
                    <Route
                      path="/eventSession/:sessionId"
                      component={EventTrackingSession}
                    />
                    <Route
                      path="/website/:websiteId/eventSessions"
                      component={EventTrackingSessions}
                    />
                    <Route
                      path="/website/:websiteId/events"
                      component={EventController}
                    />
                    <Route path="/website/:websiteId" component={Website} />
                  </Switch>
                  <Route path="/clients" component={Businesses} />
                  <Route path="/client/create" component={BusinessCreate} />
                  <Route
                    path="/client/:businessId/website/create"
                    component={BusinessWebsiteCreate}
                  />
                  <Route
                    path="/client/update/:businessId"
                    component={BusinessUpdate}
                  />
                  <Route path="/agencies" component={Agencies} />
                  <Route
                    path="/agency/update/:agencyId"
                    component={AgencyUpdate}
                  />
                  <Route path="/agency/create" component={AgencyCreate} />
                  <Route path="/users" component={Users} />
                  <Route path="/user/update/:userId" component={UserUpdate} />
                  <Route path="/user/create" component={UserCreate} />
                  <Route path="/history" component={BusinessStatistics} />
                  <Route path="/settings" component={Settings} />
                  <Route path="/docs/widgets" component={WidgetGuide} />
                  <Route
                    path="/billing/invoices"
                    component={UserLeadInvoices}
                  />
                  <Footer />
                </div>
              </div>
            ) : (
              <Login />
            )}
          </Router>
        </ThemeProvider>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    userSessionInfo: state.auth.userSessionInfo,
    navBarCollapsed: state.navBar.collapsed,
    postJson: state.io.http.postJson
  };
};

const mapDispatchToProps = {
  setUserSessionInfo,
  expandNavbar,
  toggleNavbar,
  loadFilterContext
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Root)
);
