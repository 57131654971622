import React from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { setUserSessionInfo } from "../../state/actionCreators/counter";
import WebsiteUpdateForm from "./WebsiteUpdateForm";
import { useHistory } from "react-router-dom";
import ReachPaper from "../UIComponents/ReachPaper";

function BusinessWebsiteCreate(props) {
  const { businessId } = props.match.params;

  const history = useHistory();

  const createWebsite = website => {
    const websiteWithBusinessAndAgency = {
      ...website,
      businessId,
      agencyId: props.userSessionInfo.agencyId
    };
    props
      .postJson("/v7/website", websiteWithBusinessAndAgency)
      .then(response => {
        if (response.status === 200) {
          // Go back to the previous page
          history.goBack();
        } else {
          console.warn("Failure");
        }
      });
  };

  return (
    <ReachPaper>
      <h1>Create Website</h1>
      <WebsiteUpdateForm onSubmit={createWebsite} />
    </ReachPaper>
  );
}

const mapStateToProps = state => {
  return {
    userSessionInfo: state.auth.userSessionInfo,
    postJson: state.io.http.postJson
  };
};

const mapDispatchToProps = { setUserSessionInfo };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessWebsiteCreate);
