import React, { Fragment } from "react";
import BusinessUpdateForm from "./BusinessUpdateForm";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BusinessWebsites from "./BusinessWebsites";
import UsersTable from "./Users/UsersTable";
import tableIcons from "../UIComponents/Table/tableIcons";
import AddBox from "@material-ui/icons/AddBox";
import IconButton from "../UIComponents/Button/IconButton";
import UserAutocompleteSelector from "./Users/UserAutocompleteSelector";
import SectionHeader from "../UIComponents/Typography/SectionHeader";
import ReachPaper from "../UIComponents/ReachPaper";

function BusinessUpdate(props) {
  const history = useHistory();

  const { businessId } = props.match.params;
  const [business, setBusiness] = useState(null);
  const [businessUsers, setBusinessUsers] = useState(null);
  const [agencyUsers, setAgencyUsers] = useState(null);
  const [usersToAddToBusiness, setUsersToAddToBusiness] = useState([]);

  const updateBusiness = business => {
    props.putJson("/v7/business/" + business.id, business).then(response => {
      if (response.status === 200) {
        history.push("/clients");
      } else {
        console.warn("Failure");
      }
    });
  };

  const refreshBusinessUsers = business => {
    return props
      .getJson("/v7/business/" + business.id + "/users")
      .then(response => {
        if (response.status === 200) {
          const businessUsers = response.json;

          setBusinessUsers(businessUsers);

          const businessUserIds = businessUsers.map(user => user.id);

          return refreshAgencyUsers(business.agencyId, businessUserIds);
        }
      });
  };

  const refreshAgencyUsers = (agencyId, businessUserIds) => {
    setAgencyUsers(null);
    props.getJson("/v7/agency/" + agencyId + "/users").then(response => {
      if (response.status === 200) {
        const agencyUsers = response.json;

        const agencyUsersNotAlreadyAssignedToBusiness =
          agencyUsers === null
            ? null
            : agencyUsers.filter(user => !businessUserIds.includes(user.id));

        setAgencyUsers(agencyUsersNotAlreadyAssignedToBusiness);
      }
    });
  };

  const refreshBusiness = () => {
    props.getJson("/v7/business/" + businessId).then(response => {
      if (response.status === 200) {
        const business = response.json;
        setBusiness(business);
        refreshBusinessUsers(business);
      }
    });
  };

  useEffect(() => {
    refreshBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeUserFromBusiness = (businessId, userId) =>
    props.delete("/v7/business/" + businessId + "/user/" + userId);

  const removeUserFromBusinessAction = user => ({
    icon: () => <tableIcons.Clear />,
    tooltip: "Remove User From Business",
    onClick: (event, user) => {
      removeUserFromBusiness(businessId, user.id).then(refreshBusiness);
    }
  });

  const grantSelectedUsersAccessToBusiness = () => {
    if (usersToAddToBusiness.length > 0) {
      const userIds = usersToAddToBusiness.map(user => user.id);
      props
        .putJson("/v7/business/" + businessId + "/users", userIds)
        .then(refreshBusiness);
    }
  };

  return (
    <Fragment>
      <ReachPaper>
        <h1>Update Client</h1>
        {!!business ? (
          <BusinessUpdateForm business={business} onSubmit={updateBusiness} />
        ) : (
          <span>Loading</span>
        )}
      </ReachPaper>
      <BusinessWebsites businessId={businessId} />
      <div style={{ marginBottom: "20px" }} />
      {agencyUsers && (
        <ReachPaper>
          <SectionHeader>Add Users</SectionHeader>
          <UserAutocompleteSelector
            onChange={(event, selectedUsers) => {
              setUsersToAddToBusiness(selectedUsers);
            }}
            users={agencyUsers}
          />
          <IconButton
            variant="contained"
            onClick={grantSelectedUsersAccessToBusiness}
            type="submit"
            style={{ "margin-top": "10px" }}
            icon={<AddBox />}
          >
            Add Users
          </IconButton>
        </ReachPaper>
      )}
      {!!businessUsers && (
        <UsersTable
          users={businessUsers}
          userActions={[removeUserFromBusinessAction]}
        />
      )}
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson,
    putJson: state.io.http.putJson,
    delete: state.io.http.delete
  };
};

export default connect(mapStateToProps)(BusinessUpdate);
