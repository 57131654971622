import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

class AdCampaign extends Component {
  state = {
    adCampaign: null
  };

  componentDidMount() {
    const { adCampaignId } = this.props.match.params;
    const setState = this.setState.bind(this);
    this.props.getJson("/v7/adCampaign/" + adCampaignId).then(response => {
      setState({
        adCampaign: response.json.adCampaign,
        providerAdCampaigns: response.json.providerAdCampaigns
      });
    });
  }
  render() {
    return (
      <Fragment>
        {this.state.adCampaign && (
          <Fragment>
            <h1>Ad Campaign Details</h1>
            Name: {this.state.adCampaign.name}
            <br />
            UTM ID: {this.state.adCampaign.utmId}
            <br />
            {this.state.providerAdCampaigns.map(providerAdCampaign => {
              return (
                <Fragment>
                  <br />
                  <br />
                  Provider: {providerAdCampaign.provider}
                  <br />
                  Start Date: {providerAdCampaign.dateStart}
                  <br />
                  End Date: {providerAdCampaign.dateEnd}
                  <br />
                  Impressions: {providerAdCampaign.impressions}
                  <br />
                  Spend: ${providerAdCampaign.spend}
                  <br />
                </Fragment>
              );
            })}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson
  };
};

export default connect(mapStateToProps)(AdCampaign);
