import React, { Fragment } from "react";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import EventsTimeline from "./EventsTimeline";
import { Map } from 'pigeon-maps'
import ReachMap from "../Map/ReachMap";
import SectionHeader from "../UIComponents/Typography/SectionHeader";
import ReachPaper from "../UIComponents/ReachPaper";

function EventTrackingSession(props) {
  const { sessionId } = props.match.params;

  const [session, setSession] = useState(null);

  useEffect(() => {
    props.getJson("/v7/eventSession/" + sessionId).then(response => {
      setSession(response.json);
    });
  }, []);

  const hasGeolocation = !!session &&
      !!session.geolocation

  const hasLatLng = hasGeolocation &&
      !!session.geolocation.latitude &&
      !!session.geolocation.longitude

  const hasEmoji = hasGeolocation &&
      !!session.geolocation.location &&
      !!session.geolocation.location.country_flag_emoji

  return (
    <div>
      {!!session && (
        <Fragment>
          {hasLatLng && <ReachMap lat={session.geolocation.latitude} lng={session.geolocation.longitude} />}

          <ReachPaper style={{ marginTop: "20px" }}>
            <SectionHeader>Details</SectionHeader>
            {hasGeolocation && <div>
              Location: {session.geolocation.location.country_flag_emoji} {session.geolocation.city}, {session.geolocation.region_name} {session.geolocation.zip}
            </div>}
            <SectionHeader style={{"marginTop": "20px"}}>Events</SectionHeader>
            <EventsTimeline events={session.events} />
          </ReachPaper>
        </Fragment>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventTrackingSession);
