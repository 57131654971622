import React, { Fragment } from "react";
import { Line, Bar } from "react-chartjs-2";
import { connect } from "react-redux";

import "./BusinessStatistics.css";
import reachStringify from "../../util/stringify";
import ProviderIcon from "../Provider/ProviderIcon";
import { lightProviderColor } from "../Provider/providerColors";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import FilterPanel from "../UIComponents/FilterContext/FilterPanel";
import Typography from "@material-ui/core/Typography";
import ReachPaper from "../UIComponents/ReachPaper";

const baseChartOptions = {
  legend: {
    position: "bottom"
  },
  responsive: true,
  title: {
    display: false
  },
  tooltips: {
    mode: "index",
    intersect: false
  },
  hover: {
    mode: "index"
  },
  elements: {
    line: {
      // showLine: false,
      // fill: "-1"
    }
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          z: 1
        },
        stacked: true
      }
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
          z: 1
        },
        stacked: true
      }
    ]
  }
};

class BusinessStatistics extends React.Component {
  state = {};

  componentDidMount() {
    this.loadReports();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // If our filters changed, refresh
    if (prevProps.filterHash !== this.props.filterHash) {
      this.loadReports();
    }
  }

  loadReports() {
    const setState = this.setState.bind(this);

    const queryString = reachStringify(this.props.filters);
    this.props
      .getJson("/v7/agency/business/statistics?" + queryString)
      .then(response => {
        if (response.status !== 200) {
          return;
        }

        var impressionsChartOptions = baseChartOptions;

        var spendChartOptions = baseChartOptions;

        var impressionsChartData = {
          labels: response.json.periods
            .map(period => period.substr(5, 5))
            .map(period => period.replace("-", "/")),
          datasets: response.json.providerAdCampaigns
            .filter(providerAdCampaignStats => {
              // Only show campaigns with impression data
              return (
                providerAdCampaignStats.impressions.reduce((a, b) => a + b) > 0
              );
            })
            .map(providerAdCampaignStats => {
              const providerAdCampaign =
                providerAdCampaignStats.providerAdCampaign;
              return {
                label: providerAdCampaign.name + " Ad Impressions",
                fill: true,
                borderWidth: -1,
                pointRadius: 0,
                spanGaps: false,
                backgroundColor: lightProviderColor(
                  providerAdCampaign.provider
                ),
                borderColor: lightProviderColor(providerAdCampaign.provider),
                data: providerAdCampaignStats.impressions.map(stat =>
                  stat === null ? 0 : stat
                )
              };
            })
        };

        var spendChartData = {
          labels: response.json.periods
            .map(period => period.substr(5, 5))
            .map(period => period.replace("-", "/")),
          datasets: response.json.providerAdCampaigns
            .filter(providerAdCampaignStats => {
              // Only show campaigns with impression data
              return providerAdCampaignStats.spend.reduce((a, b) => a + b) > 0;
            })
            .map(providerAdCampaignStats => {
              const providerAdCampaign =
                providerAdCampaignStats.providerAdCampaign;
              return {
                label: providerAdCampaign.name + " Spend",
                fill: true,
                borderWidth: -1,
                pointRadius: 0,
                spanGaps: false,
                backgroundColor: lightProviderColor(
                  providerAdCampaign.provider
                ),
                borderColor: lightProviderColor(providerAdCampaign.provider),
                data: providerAdCampaignStats.spend.map(stat =>
                  stat === null ? 0 : stat
                )
              };
            })
        };

        const leadChartData = {
          labels: response.json.periods
            .map(period => period.substr(5, 5))
            .map(period => period.replace("-", "/")),
          datasets: response.json.providerLeads
            .filter(providerLeads => {
              // Only show campaigns with impression data
              return providerLeads.leads.reduce((a, b) => a + b) > 0;
            })
            .map((providerLeads, index) => ({
              fill: true,
              borderWidth: -1,
              pointRadius: 0,
              spanGaps: false,
              label: providerLeads.provider + " Leads",
              backgroundColor: lightProviderColor(providerLeads.provider),
              borderColor: lightProviderColor(providerLeads.provider),
              data: providerLeads.leads
            }))
        };

        const leadChartOptions = baseChartOptions;

        setState({
          leadChartData,
          leadChartOptions,
          impressionsChartData,
          spendChartData,
          impressionsChartOptions,
          spendChartOptions,
          totalLeads: response.json.totalLeads,
          totalClients: response.json.totalClients,
          providerStats: response.json.providerStats
        });
      });
  }

  render() {
    return (
      <Fragment>
        <FilterPanel />
        <TableContainer
          component={props => (
            <ReachPaper style={{ marginBottom: "20px" }}>
              {props.children}
            </ReachPaper>
          )}
        >
          <Typography
            variant="h6"
            gutterBottom
            style={{ padding: "20px", paddingBottom: "0", marginBottom: "0" }}
          >
            Breakdown
          </Typography>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Network</TableCell>
                <TableCell align="right">Spend</TableCell>
                <TableCell align="right">Impressions</TableCell>
                <TableCell align="right">Clicks</TableCell>
                <TableCell align="right">Leads</TableCell>
                <TableCell align="right">Clients</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(this.state.providerStats || []).map(row => (
                <TableRow key={row.provider}>
                  <TableCell component="th" scope="row" align="left">
                    <ProviderIcon providerName={row.provider} />
                  </TableCell>
                  <TableCell align="right">${row.spend}</TableCell>
                  <TableCell align="right">{row.impressions}</TableCell>
                  <TableCell align="right">{row.clicks}</TableCell>
                  <TableCell align="right">{row.leads}</TableCell>
                  <TableCell align="right">{row.clients}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ minHeight: "200px", marginBottom: "20px" }}>
          {this.state.leadChartData && (
            <ReachPaper>
              <Typography
                variant="h6"
                gutterBottom
                style={{ padding: "20px", paddingBottom: "0" }}
              >
                Leads
              </Typography>
              <Bar
                data={this.state.leadChartData}
                options={this.state.leadChartOptions}
              />
            </ReachPaper>
          )}
        </div>
        <div style={{ minHeight: "200px", marginBottom: "20px" }}>
          {this.state.spendChartData && (
            <ReachPaper>
              <Typography
                variant="h6"
                gutterBottom
                style={{ padding: "20px", paddingBottom: "0" }}
              >
                Spend
              </Typography>
              <Bar
                data={this.state.spendChartData}
                options={this.state.spendChartOptions}
              />
            </ReachPaper>
          )}
        </div>
        <div style={{ minHeight: "200px", marginBottom: "20px" }}>
          {this.state.impressionsChartData && (
            <ReachPaper>
              <Typography
                variant="h6"
                gutterBottom
                style={{ padding: "20px", paddingBottom: "0" }}
              >
                Impressions
              </Typography>
              <Line
                data={this.state.impressionsChartData}
                options={this.state.impressionsChartOptions}
              />
            </ReachPaper>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson,
    filters: state.filterContext.filters,
    filterHash: state.filterContext.filterHash
  };
};

export default connect(mapStateToProps)(BusinessStatistics);
