import React from "react";

import { connect } from "react-redux";
import { useEffect, useState } from "react";
import UserLeadInvoicesTable from "./UserLeadInvoicesTable";

function UserLeadInvoices(props) {
  const [userLeadInvoices, setUserLeadInvoices] = useState([]);

  useEffect(() => {
    props.getJson("/v7/billing/userLeadInvoices").then(response => {
      setUserLeadInvoices(response.json);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <UserLeadInvoicesTable userLeadInvoices={userLeadInvoices} />;
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson
  };
};

export default connect(mapStateToProps)(UserLeadInvoices);
