import React from "react";

import { FaGoogle, FaFacebook, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import {
  facebookBlue,
  googleRed,
  twitterBlue,
  linkedInBlue
} from "./providerColors";

// TODO - normalize provider names on backend
export default function ProviderIcon({ providerName, style }) {
  if (
    providerName === "GoogleAds" ||
    providerName === "google-ads" ||
    providerName === "Google" ||
    providerName === "google" ||
    providerName === "https://www.google.com/"
  ) {
    return (
      <FaGoogle style={{ color: googleRed, fontSize: "1.4em", ...style }} />
    );
  } else if (
    providerName === "Facebook" ||
    providerName === "facebook" ||
    providerName === "fb"
  ) {
    return (
      <FaFacebook
        style={{ color: facebookBlue, fontSize: "1.4em", ...style }}
      />
    );
  } else if (providerName === "LinkedIn" || providerName === "linkedin") {
    return (
      <FaLinkedinIn
        style={{ color: linkedInBlue, fontSize: "1.4em", ...style }}
      />
    );
  } else if (
    providerName === "Twitter" ||
    providerName === "twitter" ||
    providerName === "TwitterAds"
  ) {
    return (
      <FaTwitter style={{ color: twitterBlue, fontSize: "1.4em", ...style }} />
    );
  } else {
    return <span style={style}>{providerName}</span>;
  }
}
