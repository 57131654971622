import React from "react";

// Import React Table
import "react-table/react-table.css";

import MaterialTable from "material-table";
import SectionHeader from "../UIComponents/Typography/SectionHeader";
import tableIcons from "../UIComponents/Table/tableIcons";
import { formatDollars } from "../../util/formatCurrency";
import { prettyDateTime } from "../../util/prettyDate";
import ReachPaper from "../UIComponents/ReachPaper";

const columns = [
  {
    title: "Number",
    render: invoice => <a href={invoice.pdfUrl}>{invoice.number}</a>
  },
  {
    title: "Total",
    render: invoice => formatDollars(invoice.total)
  },
  {
    title: "Status",
    field: "status"
  },
  {
    title: "Updated",
    render: invoice => prettyDateTime(invoice.lastUpdated)
  },
  {
    title: "Message",
    field: "message"
  }
];

function Purchases(props) {
  const { userLeadInvoices } = props;

  return (
    <MaterialTable
      components={{
        Container: props => <ReachPaper {...props} />
      }}
      columns={columns}
      data={userLeadInvoices}
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 50, 100, 200],
        ...props.options
      }}
      classes={{ toolbar: { title: "myTitleClass" } }}
      title={<SectionHeader>Invoices</SectionHeader>}
      icons={tableIcons}
    />
  );
}

export default Purchases;
