import SectionHeader from "../UIComponents/Typography/SectionHeader";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import React, { Fragment } from "react";
import { prettyDateTime } from "../../util/prettyDate";
import ReachPaper from "../UIComponents/ReachPaper";
import { Link } from "react-router-dom";

function EventsTimeline(props) {
  const { events } = props;
  return (
      <Stepper orientation="vertical">
        {events.map((event, index) => {
          // let details;
          // try {
          //   const formData = JSON.parse(event.details);
          //   if (Array.isArray(formData)) {
          //     details = formData.map(field => {
          //       return (
          //         <div>
          //           <b style={{ color: "black" }}>{field.name}:</b>{" "}
          //           {field.value}
          //         </div>
          //       );
          //     });
          //   }
          // } catch (e) {
          //   details = event.details;
          // }

          return (
            <Step key={"asdf"}>
              <StepLabel
                icon={events.length - index}
                completed={false}
                disabled={true}
                active={false}
              >
                <b style={{ color: "black" }}>{event.type}</b>
                <br />
                {event.details.map(detail => {
                  return (
                    <Fragment>
                      <b>{detail.name}:</b> {detail.value}
                      <br />
                    </Fragment>
                  );
                })}
                Page Title:{" "}
                <a href={event.context.pageUrl}>{event.context.pageTitle}</a>
                <br />
                Page Url: {event.context.pageUrl}
                <br />
                {prettyDateTime(event.timestamp)}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
  );
}

export default EventsTimeline;
