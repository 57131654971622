import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateFilters,
  updateOptions
} from "../../../state/actionCreators/counter";
import ReachMultiSelect from "./ReachMultiSelect";
import ReachDateRangePickerNew from "./ReachDateRangePickerNew";
import Paper from "@material-ui/core/Paper";
import ReachPaper from "../ReachPaper";

class FilterPanel extends Component {
  state = {
    businessId: []
  };

  componentDidMount() {
    this.props.getJson("/v7/businesses").then(resp => {
      if (resp.status !== 200) {
        return;
      }
      this.props.updateOptions("businessId", resp.json.businesses);
    });
    this.props.getJson("/v7/agencies").then(resp => {
      if (resp.status !== 200) {
        return;
      }
      this.props.updateOptions("agencyId", resp.json.agencies);
    });
  }

  render() {
    return (
      <ReachPaper
        style={{
          marginBottom: "20px"
        }}
      >
        {this.props.options.agencyId.length > 1 && (
          <ReachMultiSelect
            title="Agency"
            optionFormatter={agency => ({
              label: agency.name,
              value: agency.id
            })}
            options={this.props.options.agencyId}
            selected={this.props.filters.agencyId}
            selectFilters={selected =>
              this.props.updateFilters({ agencyId: selected })
            }
          />
        )}
        {this.props.options.businessId.length > 1 && (
          <ReachMultiSelect
            title="Client"
            optionFormatter={business => ({
              label: business.name,
              value: business.id
            })}
            options={this.props.options.businessId}
            selected={this.props.filters.businessId}
            selectFilters={selected =>
              this.props.updateFilters({ businessId: selected })
            }
          />
        )}
        <ReachDateRangePickerNew
          title="Dates"
          startDate={this.props.filters.startDate}
          endDate={this.props.filters.endDate}
          selectDateRange={(startDate, endDate) => {
            this.props.updateFilters({
              startDate: startDate,
              endDate: endDate
            });
          }}
        />
      </ReachPaper>
    );
  }
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson,
    filters: state.filterContext.filters,
    options: state.filterContext.options
  };
};

const mapDispatchToProps = { updateFilters, updateOptions };

export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel);
