import React from "react";

const Footer = () => {
  return (
    <footer style={{ textAlign: "center" }}>
      Copyright © 2020 Reach LLC
      <br />
      All Rights Reserved
      <br />
      <a href="https://choosereach.com/privacy">Privacy Policy</a>
      {"   "}
      <a href="https://choosereach.com/terms">Terms and Conditions</a>
      {"   "}
      <a href="https://choosereach.com/disclaimer">Disclaimer</a>
      {"   "}
      <a href="https://choosereach.com/cookies">Cookie Policy</a>
      <br />
      <span>
        Made in
        <span role="img" aria-label="USA Flag">
          {" "}
          {/*There is a usa flag emoji here (can't see in code editors)*/}
          🇺🇲
        </span>
      </span>
    </footer>
  );
};
export default Footer;
