import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import ReachTextField from "../UIComponents/ReachTextField";

function AgencyUpdateForm(props) {
  const maybeAgency = props.agency;
  const onSubmit = props.onSubmit;

  const initialValues = !!maybeAgency
    ? maybeAgency
    : {
        name: "",
        senderAddressLeads: ""
      };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Required"),
        senderAddressLeads: Yup.string()
          .email()
          .required("Required")
      })}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        } = props;

        console.log(errors);

        return (
          <form onSubmit={handleSubmit}>
            <ReachTextField
              id="name"
              value={values.name}
              label="Agency Name"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.name && touched.name}
              helperText={errors.name}
            />
            <br />
            <br />

            <ReachTextField
              id="senderAddressLeads"
              value={values.senderAddressLeads}
              label="Sender Email address"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.senderAddressLeads && touched.senderAddressLeads}
              helperText={errors.senderAddressLeads}
            />
            <br />
            <br />

            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </form>
        );
      }}
    </Formik>
  );
}

export default AgencyUpdateForm;
