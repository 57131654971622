import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    marginLeft: 0,
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(item, selected, theme) {
  const values = selected.map(item => item.value);
  return {
    fontWeight:
      values.indexOf(item.value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}

export default function ReachMultiSelect(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [selected, setSelected] = React.useState([]);
  const formattedOptions = props.options.map(props.optionFormatter);

  const formattedOptionsMap = formattedOptions.reduce(
    (acc, cur) => ({ ...acc, [cur.value]: cur.label }),
    {}
  );

  const handleChange = event => {
    props.selectFilters(event.target.value);
    setSelected(event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-mutiple-chip-label">{props.title}</InputLabel>
      <Select
        labelId="demo-mutiple-chip-label"
        id="demo-mutiple-chip"
        multiple
        value={props.selected}
        onChange={handleChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={selected => (
          <div className={classes.chips}>
            {selected.map(item => (
              <Chip
                key={item}
                label={formattedOptionsMap[item]}
                className={classes.chip}
              />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {formattedOptions.map((option, index) => (
          <MenuItem
            key={option.value}
            value={option.value}
            style={getStyles(option, selected, theme)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
