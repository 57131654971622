import React from "react";
import { Paper } from "@material-ui/core";

function ReachPaper(props) {
  return (
    <Paper
      elevation={0}
      style={{
        padding: "20px",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        ...props.style
      }}
    >
      {props.children}
    </Paper>
  );
}

export default ReachPaper;
