import React from "react";
import ReactDOM from "react-dom";

import store from "./state/store";

import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";

import config from "./config/index";

// Webflow requires normalize.css
import "normalize.css";

import "./index.css";
import Root from "./Root";
import * as serviceWorker from "./serviceWorker";

const bugsnagClient = bugsnag({
  apiKey: "e8d01d359082ff620d743bab31b1a356",
  notifyReleaseStages: ["production"],
  releaseStage: config.releaseStage
});
bugsnagClient.use(bugsnagReact, React);

const rootElement = document.getElementById("root");

const ErrorBoundary = bugsnagClient.getPlugin("react");
ReactDOM.render(
  <ErrorBoundary>
    <Root store={store} />
  </ErrorBoundary>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
