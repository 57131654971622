import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";

function WebsiteUpdateForm(props) {
  const maybeBusiness = props.business;
  const onSubmit = props.onSubmit;

  const initialValues = !!maybeBusiness
    ? maybeBusiness
    : {
        name: "",
        url: ""
      };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Required"),
        url: Yup.string()
          .url()
          .required("Required")
      })}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        } = props;

        console.log(errors);

        return (
          <form onSubmit={handleSubmit}>
            <label htmlFor="name" style={{ display: "block" }}>
              Website Name
            </label>
            <input
              id="name"
              placeholder="Website name"
              type="text"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.name && touched.name ? "text-input error" : "text-input"
              }
            />
            {errors.name && touched.name && (
              <div className="input-feedback">{errors.name}</div>
            )}
            <br />
            <br />

            <label htmlFor="url" style={{ display: "block" }}>
              Website Url
            </label>
            <input
              id="url"
              placeholder="Website url"
              type="text"
              value={values.url}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.url && touched.url ? "text-input error" : "text-input"
              }
            />
            {errors.url && touched.url && (
              <div className="input-feedback">{errors.url}</div>
            )}
            <br />
            <br />

            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </form>
        );
      }}
    </Formik>
  );
}

export default WebsiteUpdateForm;
