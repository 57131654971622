import React, { Fragment } from "react";

// Import React Table
import "react-table/react-table.css";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import SectionHeader from "../../UIComponents/Typography/SectionHeader";
import tableIcons from "../../UIComponents/Table/tableIcons";
import { requireRole } from "../../../util/accessControl";
import ReachPaper from "../../UIComponents/ReachPaper";

const columns = [
  {
    title: "",
    render: user => <Link to={"/user/update/" + user.id}>{user.email}</Link>,
    cellStyle: {
      paddingRight: 0
    },
    headerStyle: {
      paddingRight: 0
    }
  }
];

function UsersTable(props) {
  const users = props.users;

  const impersonateUserInNewTab = userId => {
    props.postJson("/v7/impersonate/" + userId, {}).then(response => {
      const token = response.json.UserSession.jwt;
      const newUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        "?impersonateToken=" +
        encodeURIComponent(token);
      console.log("Open: " + newUrl);
      window.open(newUrl, "_blank");
    });
  };

  return (
    <Fragment>
      <MaterialTable
        components={{
          Container: props => <ReachPaper {...props} />
        }}
        columns={columns}
        data={users}
        search={false}
        actions={[
          ...props.userActions,
          ...(requireRole(props.userSessionInfo, "Super User")
            ? [
                rowData => ({
                  iconProps: (a, b) => {},
                  icon: (a, b) => {
                    return <tableIcons.LaunchIcon />;
                  },
                  tooltip: "Impersonate User",
                  onClick: (event, rowData) =>
                    impersonateUserInNewTab(rowData.id)
                })
              ]
            : [])
        ]}
        options={{
          pageSize: 5,
          pageSizeOptions: [5, 50, 100, 200]
        }}
        classes={{ toolbar: { title: "myTitleClass" } }}
        title={<SectionHeader>Users</SectionHeader>}
        icons={tableIcons}
      />
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    postJson: state.io.http.postJson,
    userSessionInfo: state.auth.userSessionInfo
  };
};

UsersTable.defaultProps = {
  userActions: []
};

export default connect(mapStateToProps)(UsersTable);
