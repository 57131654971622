import React, { Component, Fragment } from "react";

import "react-table/react-table.css";

import { connect } from "react-redux";
import reachStringify from "../../util/stringify";
import MaterialTable from "material-table";

import ProviderIcon from "../Provider/ProviderIcon";
import { formatDollars } from "../../util/formatCurrency";
import FilterPanel from "../UIComponents/FilterContext/FilterPanel";
import tableIcons from "../UIComponents/Table/tableIcons";
import ReachPaper from "../UIComponents/ReachPaper";

const columns = [
  {
    title: "Network",
    render: row => <ProviderIcon providerName={row.provider} />
  },
  {
    title: "Name",
    field: "campaignName"
  },
  // {
  //   title: "# Calls",
  //   field: "callCount"
  // },
  // {
  //   title: "# Forms",
  //   field: "formCount"
  // },
  {
    title: "# Impressions",
    field: "impressions"
  },
  {
    title: "# Clicks",
    field: "clicks"
  },
  {
    title: "# Leads",
    field: "leadCount"
  },
  {
    title: "# Clients",
    field: "clientCount"
  },
  {
    title: "Spend",
    render: row => formatDollars(row.spend)
  },
  {
    title: "Cost Per Impression",
    render: row => formatDollars(row.costPerImpression)
  },
  {
    title: "Cost Per Click",
    render: row => formatDollars(row.costPerClick)
  },
  {
    title: "Cost Per Lead",
    render: row => formatDollars(row.costPerLead)
  },
  {
    title: "Click Through Rate",
    render: row => parseFloat(row.conversionRate * 100).toFixed(2) + "%"
  }
];

const mockCampaigns = [];

class Performance extends Component {
  state = {
    adCampaigns: mockCampaigns
  };
  loadReports() {
    const queryString = reachStringify(this.props.filters);
    this.props
      .getJson("/v7/agency/business/performance?" + queryString)
      .then(response => {
        response.status === 200 &&
          this.setState({
            adCampaigns: response.json.adCampaigns.concat(
              response.json.adCampaignsTotal
            )
          });
      });
  }

  componentDidMount() {
    this.loadReports();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // If our filters changed, refresh
    if (prevProps.filterHash !== this.props.filterHash) {
      this.loadReports();
    }
  }

  render() {
    const { adCampaigns } = this.state;

    return (
      <Fragment>
        <FilterPanel />
        <MaterialTable
          components={{
            Container: props => <ReachPaper {...props} />
          }}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 50, 100]
          }}
          columns={columns}
          data={adCampaigns}
          title="Ad Performance"
          icons={tableIcons}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson,
    filters: state.filterContext.filters,
    filterHash: state.filterContext.filterHash
  };
};

export default connect(mapStateToProps)(Performance);
