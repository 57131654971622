import React, { Component, Fragment } from "react";

import "react-table/react-table.css";

import { connect } from "react-redux";
import MaterialTable from "material-table";

import ProviderIcon from "../Provider/ProviderIcon";
import FilterPanel from "../UIComponents/FilterContext/FilterPanel";
import { formatDollarsRounded } from "../../util/formatCurrency";
import { Link } from "react-router-dom";
import tableIcons from "../UIComponents/Table/tableIcons";
import ReachPaper from "../UIComponents/ReachPaper";

const columns = [
  {
    title: "Network",
    render: row => <ProviderIcon providerName={row.provider} />
  },
  {
    title: "Status",
    field: "status"
  },
  {
    title: "Name",
    render: row => <Link to={"/providerAdCampaign/" + row.id}>{row.name}</Link>
  },
  {
    title: "Daily Budget",
    render: row => formatDollarsRounded(row.dailyBudget)
  }
  // {
  //   title: "Daily Pacing",
  //   render: row => "50%"
  //   // render: row => "daily spend / daily budget"
  // },
  // {
  //   title: "Lifetime Pacing",
  //   render: row => "19%"
  //   // render: row => "lifetime spend / lifetime budget"
  // },
  // {
  //   title: "Remaining Budget",
  //   render: row => formatDollarsRounded(row.remainingBudget)
  // }
];

const mockCampaigns = [];

class Budget extends Component {
  state = {
    adCampaigns: mockCampaigns
  };
  loadReports() {
    this.props.getJson("/v7/adCampaigns").then(response => {
      response.status === 200 &&
        this.setState({
          adCampaigns: response.json.adCampaigns
        });
    });
  }

  componentDidMount() {
    this.loadReports();
  }

  startCampaign(providerCampaignId) {
    return this.props.post(
      "/v7/providerAdCampaign/" + providerCampaignId + "/start"
    );
  }

  stopCampaign(providerCampaignId) {
    return this.props.post(
      "/v7/providerAdCampaign/" + providerCampaignId + "/stop"
    );
  }

  render() {
    const { adCampaigns } = this.state;
    const loadReports = this.loadReports.bind(this);

    return (
      <Fragment>
        <MaterialTable
          components={{
            Container: props => <ReachPaper {...props} />
          }}
          columns={columns}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 50, 100]
          }}
          actions={[
            rowData => ({
              iconProps: (a, b) => {},
              icon: (a, b) => {
                return (
                  <tableIcons.PlayCircleOutline
                    style={rowData.status !== "Paused" ? { color: "gray" } : {}}
                  />
                );
              },
              tooltip: "Start Campaign",
              onClick: (event, rowData) =>
                rowData.status === "Paused"
                  ? this.startCampaign(rowData.id).then(loadReports)
                  : console.warn("Cannot start a campaign that is not paused.")
            }),
            rowData => ({
              icon: (a, b) => {
                return (
                  <tableIcons.PauseCircleOutline
                    style={rowData.status !== "Active" ? { color: "gray" } : {}}
                  />
                );
              },
              tooltip: "Stop Campaign",
              onClick: (event, rowData) =>
                rowData.status === "Active"
                  ? this.stopCampaign(rowData.id).then(loadReports)
                  : console.warn("Cannot pause a campaign that is not active.")
            })
          ]}
          data={adCampaigns}
          title="Campaigns"
          icons={tableIcons}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson,
    post: state.io.http.post
  };
};

export default connect(mapStateToProps)(Budget);
