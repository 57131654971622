import React from "react";
import BusinessUpdateForm from "./BusinessUpdateForm";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import { setUserSessionInfo } from "../../state/actionCreators/counter";
import ReachPaper from "../UIComponents/ReachPaper";

function BusinessCreate(props) {
  const history = useHistory();

  const createBusiness = business => {
    const businessWithAgency = {
      ...business,
      agencyId: props.userSessionInfo.agencyId
    };
    props.postJson("/v7/business", businessWithAgency).then(response => {
      if (response.status === 200) {
        props.postJson("/v7/refreshToken").then(response => {
          // Refresh the users token so the business is accessible
          localStorage.setItem(
            "reach.userSessionInfo",
            JSON.stringify(response.json.UserSession)
          );
          props.setUserSessionInfo(response.json.UserSession);
          history.push("/clients");
        });
      } else {
        console.warn("Failure");
      }
    });
  };

  return (
    <ReachPaper>
      <h1>Create Client</h1>
      <BusinessUpdateForm onSubmit={createBusiness} />
    </ReachPaper>
  );
}

const mapStateToProps = state => {
  return {
    userSessionInfo: state.auth.userSessionInfo,
    postJson: state.io.http.postJson
  };
};

const mapDispatchToProps = { setUserSessionInfo };

export default connect(mapStateToProps, mapDispatchToProps)(BusinessCreate);
