import React, { useEffect, useState } from "react";
import FeedbackCard from "./FeedbackCard";
import EventsTimeline from "../EventTracking/EventsTimeline";
import { connect } from "react-redux";

function Feedback(props) {
  const { feedbackId } = props.match.params;
  const [feedback, setFeedback] = useState(null);
  const [feedbackEvents, setFeedbackEvents] = useState(null);

  useEffect(() => {
    props.getJson("/v7/feedback/" + feedbackId).then(response => {
      setFeedback(response.json);
    });
    props.getJson("/v7/feedback/" + feedbackId + "/events").then(response => {
      setFeedbackEvents(response.json);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h1>Feedback Details</h1>
      {feedback && <FeedbackCard feedback={feedback} showDetailLink={false} />}
      {feedbackEvents && <EventsTimeline events={feedbackEvents} />}
    </div>
  );
}

const mapStateToProps = state => ({
  getJson: state.io.http.getJson
});

export default connect(mapStateToProps)(Feedback);
