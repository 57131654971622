import React from "react";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import { phoneRegExp } from "../../util/formValidation";
import ReachTextField from "../UIComponents/ReachTextField";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import { default as MaterialIconButton } from "@material-ui/core/IconButton";
import IconButton from "../UIComponents/Button/IconButton";

function BusinessUpdateForm(props) {
  const maybeBusiness = props.business;
  const onSubmit = props.onSubmit;

  const initialValues = !!maybeBusiness
    ? maybeBusiness
    : {
        name: "",
        recipientAddressLeads: [],
        recipientAddressLeadsSms: []
      };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Required"),
        recipientAddressLeads: Yup.array().of(
          Yup.string().email("Must be a valid email")
        ),
        recipientAddressLeadsSms: Yup.array().of(
          Yup.string().matches(phoneRegExp, "Must be a valid phone number")
        )
      })}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        } = props;

        console.log(errors);

        return (
          <form onSubmit={handleSubmit}>
            <ReachTextField
              id="name"
              value={values.name}
              label="Business Name"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.name && touched.name}
              helperText={errors.name}
            />
            <br />
            <br />
            <br />
            <br />

            <label style={{ display: "block" }}>Send leads via email to:</label>
            <br />
            <FieldArray
              name="recipientAddressLeads"
              render={arrayHelpers => (
                <div>
                  {values.recipientAddressLeads &&
                    values.recipientAddressLeads.length > 0 &&
                    values.recipientAddressLeads.map((email, index) => (
                      <div key={index}>
                        <ReachTextField
                          name={`recipientAddressLeads.${index}`}
                          value={values.recipientAddressLeads[index]}
                          label="Recipient Email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            errors.recipientAddressLeads &&
                            errors.recipientAddressLeads[index] &&
                            touched.recipientAddressLeads &&
                            touched.recipientAddressLeads[index]
                          }
                          helperText={
                            errors.recipientAddressLeads &&
                            errors.recipientAddressLeads[index]
                          }
                        />
                        <MaterialIconButton
                          aria-label="delete"
                          color="primary"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <DeleteIcon />
                        </MaterialIconButton>
                        <br />
                        <br />
                      </div>
                    ))}

                  <IconButton
                    variant="contained"
                    onClick={() => arrayHelpers.push("")}
                    type="button"
                    icon={<EmailOutlinedIcon />}
                  >
                    Add Email Address
                  </IconButton>
                </div>
              )}
            />
            <br />
            <br />
            <br />

            <label style={{ display: "block" }}>Send leads via sms to:</label>
            <br />
            <FieldArray
              name="recipientAddressLeadsSms"
              render={arrayHelpers => (
                <div>
                  {values.recipientAddressLeadsSms &&
                    values.recipientAddressLeadsSms.length > 0 &&
                    values.recipientAddressLeadsSms.map((email, index) => (
                      <div key={index}>
                        <ReachTextField
                          name={`recipientAddressLeadsSms.${index}`}
                          value={values.recipientAddressLeadsSms[index]}
                          label="Recipient Phone Number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            errors.recipientAddressLeadsSms &&
                            errors.recipientAddressLeadsSms[index] &&
                            touched.recipientAddressLeadsSms &&
                            touched.recipientAddressLeadsSms[index]
                          }
                          helperText={
                            errors.recipientAddressLeadsSms &&
                            errors.recipientAddressLeadsSms[index]
                          }
                        />
                        <MaterialIconButton
                          aria-label="delete"
                          color="primary"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <DeleteIcon />
                        </MaterialIconButton>
                        <br />
                        <br />
                      </div>
                    ))}
                  <IconButton
                    variant="contained"
                    onClick={() => arrayHelpers.push("")}
                    type="button"
                    icon={<SmsOutlinedIcon />}
                  >
                    Add Phone Number
                  </IconButton>
                </div>
              )}
            />
            <br />
            <br />

            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </form>
        );
      }}
    </Formik>
  );
}

export default BusinessUpdateForm;
