import React, { Fragment } from "react";

import AssignmentIcon from "@material-ui/icons/Assignment";
import CallIcon from "@material-ui/icons/Call";
import VoicemailIcon from "@material-ui/icons/Voicemail";
import PhoneMissedIcon from "@material-ui/icons/PhoneMissed";
import PhoneDisabledIcon from "@material-ui/icons/PhoneDisabled";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import Email from "@material-ui/icons/Email";
import Textsms from "@material-ui/icons/Textsms";

export default function FormType({ formType }) {
  if (formType === "call") {
    // TODO - remove this we have more specific call types now
    return <CallIcon />;
  } else if (formType === "answeredCall") {
    return <PhoneInTalkIcon />;
  } else if (formType === "voicemail") {
    return <VoicemailIcon />;
  } else if (formType === "abandonedCall") {
    return <PhoneDisabledIcon />;
  } else if (formType === "missedCall") {
    return <PhoneMissedIcon />;
  } else if (formType === "form") {
    return <AssignmentIcon />;
  } else if (formType === "email") {
    return <Email />;
  } else if (formType === "textMessage") {
    return <Textsms />;
  } else {
    return <Fragment>{formType}</Fragment>;
  }
}
