// Render Prop
import React from "react";
import { Formik, Form } from "formik";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Person, Fingerprint } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import { logoForHost } from "../Whitelabel/whitelabel";
import ReachPaper from "../UIComponents/ReachPaper";

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit * 2
  },
  padding: {
    padding: theme.spacing.unit
  }
});

function isMobileDevice() {
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  );
}

const isMobile = isMobileDevice();

const ReachLoginForm = props => {
  const { handleChange, errorMessage, isSubmitting, setFieldTouched } = props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };
  return (
    <Form>
      <ReachPaper>
        <div id="loginFormLogoContainer">
          <img
            alt={"Reach Logo"}
            id="navBarLogo"
            src={logoForHost()}
            style={{ width: "60%", marginTop: "20px" }}
          />
        </div>
        {errorMessage && (
          <div className="loginFormErrorMessage">{errorMessage}</div>
        )}
        <div className={props.classes.margin}>
          <Grid container spacing={8} alignItems="flex-end">
            <Grid item>
              <Person />
            </Grid>
            <Grid item md={true} sm={true} xs={true} style={{ paddingLeft: 0 }}>
              <TextField
                id="email"
                label="Email"
                type="email"
                onChange={change.bind(null, "email")}
                fullWidth
                autoFocus
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={8} alignItems="flex-end">
            <Grid item>
              <Fingerprint />
            </Grid>
            <Grid item md={true} sm={true} xs={true} style={{ paddingLeft: 0 }}>
              <TextField
                id="password"
                label="Password"
                type="password"
                fullWidth
                required
                onChange={change.bind(null, "password")}
              />
            </Grid>
          </Grid>
          {/*<Grid container alignItems="center" justify="space-between">*/}
          {/*  <Grid item>*/}
          {/*    <FormControlLabel control={*/}
          {/*      <Checkbox*/}
          {/*        color="primary"*/}
          {/*      />*/}
          {/*    } label="Remember me" />*/}
          {/*  </Grid>*/}
          {/*  <Grid item>*/}
          {/*    <Button disableFocusRipple disableRipple style={{ textTransform: "none" }} variant="text" color="primary">Forgot password ?</Button>*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
          <Grid container justify="center" style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ textTransform: "none" }}
              disabled={isSubmitting}
              type="submit"
            >
              Login
            </Button>
          </Grid>
        </div>
      </ReachPaper>
    </Form>
  );
};

const ReachLoginWithStyles = withStyles(styles)(ReachLoginForm);

const LoginForm = props => {
  const errorMessage = props.errorMessage;
  return (
    <div id="loginFormContainer" style={isMobile ? { width: "100%" } : {}}>
      <Formik
        render={props => (
          <ReachLoginWithStyles errorMessage={errorMessage} {...props} />
        )}
        initialValues={{ email: "", password: "" }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            props.onSubmitHandler(values);
            setSubmitting(false);
          }, 400);
        }}
        validate={values => {
          let errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (!values.password) {
            errors.password = "Required";
          }
          return errors;
        }}
      />
    </div>
  );
};

export default withStyles(styles)(LoginForm);
