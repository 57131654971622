import React, { Fragment } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AssignmentIndTwoToneIcon from "@material-ui/icons/AssignmentIndTwoTone";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import SpeedTwoToneIcon from "@material-ui/icons/SpeedTwoTone";
import WebIcon from "@material-ui/icons/Web";
import SettingsIcon from "@material-ui/icons/Settings";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import Person from "@material-ui/icons/Person";
import BusinessIcon from "@material-ui/icons/Business";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import RateReviewIcon from "@material-ui/icons/RateReview";
import {
  collapseNavbar,
  toggleNavbar
} from "../../state/actionCreators/counter";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import "./NavigationSideBar.css";
import LogoutLink from "../Login/LogoutLink";
import MenuIcon from "@material-ui/icons/Menu";
import Fab from "@material-ui/core/Fab";
import Avatar from "@material-ui/core/Avatar";
import { logoForHost } from "../Whitelabel/whitelabel";
import { requireAtLeastOneRole, requireRole } from "../../util/accessControl";

const drawerWidth = 240;

function isMobileDevice() {
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    display: "none",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  toolbarPaper: {
    paddingTop: "50px"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}));

function NavigationSideBarNew(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const navigateTo = url => () => {
    history.push(url);
    if (isMobileDevice()) {
      props.collapseNavbar();
    }
  };

  return (
    <Fragment>
      <Fab
        onClick={props.toggleNavbar}
        id="myFab"
        style={{
          position: "fixed",
          backgroundColor: "white",
          zIndex: "10000",
          top: "5px",
          left: "5px"
        }}
      >
        <MenuIcon />
      </Fab>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: !props.navBarCollapsed,
          [classes.drawerClose]: props.navBarCollapsed
        })}
        classes={{
          paper: clsx({
            [classes.toolbarPaper]: true
          })
        }}
      >
        <div className={classes.toolbar}>
          <img
            alt="Reach Logo"
            id="navBarLogo"
            style={{ margin: "0 auto", width: "80%", maxWidth: "150px" }}
            src={logoForHost()}
          />
        </div>
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <Avatar
            style={{ margin: "0 auto", marginBottom: "5px" }}
            alt={props.userSessionInfo && props.userSessionInfo.name}
            src={props.userSessionInfo && props.userSessionInfo.profileImage}
          />
          {props.userSessionInfo &&
            props.userSessionInfo.name &&
            props.userSessionInfo.name}
        </div>
        <Divider />
        <List className={"navBarList"}>
          {/*<ListItem*/}
          {/*  button*/}
          {/*  onClick={navigateTo("/home")}*/}
          {/*  selected={location.pathname === "/home"}*/}
          {/*>*/}
          {/*  <ListItemIcon>*/}
          {/*    <HouseIcon />*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText primary={"Home"} />*/}
          {/*</ListItem>*/}
          <ListItem
            button
            onClick={navigateTo("/leads")}
            selected={
              location.pathname.includes("/lead") || location.pathname === "/"
            }
          >
            <ListItemIcon>
              <AssignmentIndTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary={"Leads"} />
          </ListItem>
          {requireRole(props.userSessionInfo, "Agency Owner") && (
            <ListItem
              button
              onClick={navigateTo("/purchases")}
              selected={location.pathname.includes("/purchase")}
            >
              <ListItemIcon>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary={"Purchases"} />
            </ListItem>
          )}

          {/*<ListItem*/}
          {/*  button*/}
          {/*  onClick={navigateTo("/performance")}*/}
          {/*  selected={location.pathname === "/performance"}*/}
          {/*>*/}
          {/*  <ListItemIcon>*/}
          {/*    <SpeedTwoToneIcon />*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText primary={"Performance"} />*/}
          {/*</ListItem>*/}
          {/*<ListItem*/}
          {/*  button*/}
          {/*  onClick={navigateTo("/budget")}*/}
          {/*  selected={location.pathname === "/budget"}*/}
          {/*>*/}
          {/*  <ListItemIcon>*/}
          {/*    <AttachMoneyIcon />*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText primary={"Campaigns"} />*/}
          {/*</ListItem>*/}
          <ListItem
            button
            onClick={navigateTo("/websites")}
            selected={location.pathname.includes("/website")}
          >
            <ListItemIcon>
              <WebIcon />
            </ListItemIcon>
            <ListItemText primary={"Websites"} />
          </ListItem>
          {/*{requireRole(props.userSessionInfo, "Super User") && (*/}
          {/*  <ListItem*/}
          {/*    button*/}
          {/*    onClick={navigateTo("/reviews")}*/}
          {/*    selected={location.pathname.includes("/review")}*/}
          {/*  >*/}
          {/*    <ListItemIcon>*/}
          {/*      <RateReviewIcon />*/}
          {/*    </ListItemIcon>*/}
          {/*    <ListItemText primary={"Reviews"} />*/}
          {/*  </ListItem>*/}
          {/*)}*/}
          {/*{requireRole(props.userSessionInfo, "Super User") && (*/}
          {/*  <ListItem*/}
          {/*    button*/}
          {/*    onClick={navigateTo("/feedback")}*/}
          {/*    selected={location.pathname.includes("/feedback")}*/}
          {/*  >*/}
          {/*    <ListItemIcon>*/}
          {/*      <ThumbUpIcon />*/}
          {/*    </ListItemIcon>*/}
          {/*    <ListItemText primary={"Feedback"} />*/}
          {/*  </ListItem>*/}
          {/*)}*/}
          {/*<ListItem*/}
          {/*  button*/}
          {/*  onClick={navigateTo("/history")}*/}
          {/*  selected={location.pathname === "/history"}*/}
          {/*>*/}
          {/*  <ListItemIcon>*/}
          {/*    <TrendingUpIcon />*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText primary={"History"} />*/}
          {/*</ListItem>*/}
          {requireAtLeastOneRole(props.userSessionInfo, ["Super User"]) && (
            <ListItem
              button
              onClick={navigateTo("/clients")}
              selected={location.pathname.includes("client")}
            >
              <ListItemIcon>
                <BusinessCenterIcon />
              </ListItemIcon>
              <ListItemText primary={"Clients"} />
            </ListItem>
          )}
          {requireRole(props.userSessionInfo, "Super User") && (
            <ListItem
              button
              onClick={navigateTo("/agencies")}
              selected={location.pathname.includes("agenc")}
            >
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary={"Agencies"} />
            </ListItem>
          )}
          {requireAtLeastOneRole(props.userSessionInfo, ["Agency Owner"]) && (
            <ListItem
              button
              onClick={navigateTo("/users")}
              selected={location.pathname.includes("user")}
            >
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText primary={"Users"} />
            </ListItem>
          )}
          {requireAtLeastOneRole(props.userSessionInfo, ["Agency Owner"]) && (
            <ListItem
              button
              onClick={navigateTo("/settings")}
              selected={location.pathname === "/settings"}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={"Settings"} />
            </ListItem>
          )}
          {/*{requireAtLeastOneRole(props.userSessionInfo, ["Agency Owner"]) && (*/}
          {/*  <ListItem*/}
          {/*    button*/}
          {/*    onClick={navigateTo("/docs/widgets")}*/}
          {/*    selected={location.pathname === "/docs/widgets"}*/}
          {/*  >*/}
          {/*    <ListItemIcon>*/}
          {/*      <MenuBookIcon />*/}
          {/*    </ListItemIcon>*/}
          {/*    <ListItemText primary={"Documentation"} />*/}
          {/*  </ListItem>*/}
          {/*)}*/}
          {/*{requireRole(props.userSessionInfo, "Super User") && (*/}
          {/*  <ListItem*/}
          {/*    button*/}
          {/*    onClick={navigateTo("/billing/invoices")}*/}
          {/*    selected={location.pathname === "/billing/invoices"}*/}
          {/*  >*/}
          {/*    <ListItemIcon>*/}
          {/*      <AttachMoneyIcon />*/}
          {/*    </ListItemIcon>*/}
          {/*    <ListItemText primary={"Invoices"} />*/}
          {/*  </ListItem>*/}
          {/*)}*/}
          <LogoutLink />
        </List>
      </Drawer>
    </Fragment>
  );
}

const mapDispatchToProps = { toggleNavbar, collapseNavbar };

const mapStateToProps = state => {
  return {
    userSessionInfo: state.auth.userSessionInfo,
    navBarCollapsed: state.navBar.collapsed
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationSideBarNew);
