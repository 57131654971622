import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import React from "react";

function UserAutocompleteSelector(props) {
  const onChange = props.onChange;
  const users = props.users;

  return (
    <Autocomplete
      onChange={onChange}
      multiple
      id="fixed-tags-demo"
      options={users}
      getOptionLabel={user => user.email}
      renderTags={(value, getTagProps) =>
        value.map((user, index) => (
          <Chip label={user.email} {...getTagProps({ index })} />
        ))
      }
      style={{ width: "100%" }}
      renderInput={params => (
        <TextField
          {...params}
          label="Fixed tag"
          variant="outlined"
          placeholder="Add Users"
        />
      )}
    />
  );
}

UserAutocompleteSelector.defaultProps = {
  onChange: console.log
};

export default UserAutocompleteSelector;
