import React, { Component } from "react";

// Import React Table
import "react-table/react-table.css";

import "./CRMLeadsController.css";

import { connect } from "react-redux";
import reachStringify from "../../util/stringify";
import FilterPanel from "../UIComponents/FilterContext/FilterPanel";
import LeadsTable from "./LeadsTable";

class CRMLeadsController extends Component {
  state = {
    leads: null,
    helpScoutConversations: {} // id => conversation map
  };

  loadReports() {
    const queryString = reachStringify(this.props.filters);
    this.setState({ leads: null });
    this.props.getJson("/v7/leads?" + queryString).then(response => {
      if (response.status !== 200) {
        return false;
      }

      const businesses = {};

      for (const business of response.json.businesses) {
        businesses[business.id] = business.name;
      }

      this.setState({
        leads: response.json.leads.map(lead => ({
          ...lead,
          businessName: businesses[lead.businessId] || ""
        }))
      });
    });
  }

  componentDidMount() {
    this.loadReports();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // If our filters changed, refresh
    if (prevProps.filterHash !== this.props.filterHash) {
      this.loadReports();
    }
  }

  render() {
    const { leads } = this.state;
    const { filters } = this.props;

    return (
      <div style={{ maxWidth: "100%" }}>
        <FilterPanel />
        {!!leads && <LeadsTable leads={leads} filters={filters} />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson,
    filters: state.filterContext.filters,
    filterHash: state.filterContext.filterHash
  };
};

export default connect(mapStateToProps)(CRMLeadsController);
