export const setUserSessionInfo = userSessionInfo => {
  return {
    type: "SET_USER",
    userSessionInfo
  };
};

//Log Out, logOut
export const unsetUser = errorMessage => {
  return {
    type: "UNSET_USER",
    errorMessage
  };
};

export const expandNavbar = () => {
  return {
    type: "EXPAND_NAVBAR"
  };
};

export const collapseNavbar = () => {
  return {
    type: "COLLAPSE_NAVBAR"
  };
};

export const toggleNavbar = () => {
  return {
    type: "TOGGLE_NAVBAR"
  };
};

export const loadFilterContext = filterContext => {
  return {
    type: "FILTER_CONTEXT_LOADED",
    filterContext
  };
};

export const updateFilters = filters => {
  return {
    type: "UPDATE_FILTERS",
    filters
  };
};

export const updateOptions = (optionAccessor, options) => {
  return {
    type: "UPDATE_OPTIONS",
    optionAccessor,
    options
  };
};
