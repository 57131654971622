import React, { Fragment, useEffect, useState } from "react";

import "./Website.css";
import reachStringify from "../../util/stringify";
import { connect } from "react-redux";
import PageVisitsTable from "./PageVisitsTable";
import ReachPaper from "../UIComponents/ReachPaper";
import SectionHeader from "../UIComponents/Typography/SectionHeader";
import { Card } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";

function Website(props) {
  const { websiteId } = props.match.params;

  const [pageVisits, setPageVisits] = useState([]);
  const [website, setWebsite] = useState(null);
  const [searchTerms, setSearchTerms] = useState([]);

  const queryString = reachStringify(props.filters);

  useEffect(() => {
    // TODO - filter by website only
    props.getJson("/v7/pageVisits?websiteId=" + websiteId).then(response => {
      setPageVisits(response.json.pageVisits);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.getJson("/v7/website/" + websiteId).then(response => {
      setWebsite(response.json.website);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props
      .getJson("/v7/pageVisits/statistics?websiteId=" + websiteId) // TODO - add provider ad campaign id
      .then(response => {
        setSearchTerms(response.json);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {website !== null && (
        <ReachPaper style={{ marginBottom: "20px" }}>
          <h1>{website.name}</h1>
          <img
            src={website.screenshot}
            className={"reach-website-card"}
            alt={website.name}
          />
          <br />
          <a href={website.url} target="_blank" rel="noopener noreferrer">
            {website.url}
          </a>
        </ReachPaper>
      )}
      <TableContainer
        component={props => <ReachPaper>{props.children}</ReachPaper>}
      >
        <Typography
          variant="h6"
          gutterBottom
          style={{
            padding: "20px",
            paddingBottom: "0",
            marginBottom: "0"
          }}
        >
          Search Terms
        </Typography>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Search Term</TableCell>
              <TableCell align="right">Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchTerms.map(searchTerm => (
              <TableRow key={searchTerm.utmTerm}>
                <TableCell align="left">{searchTerm.utmTerm}</TableCell>
                <TableCell align="right">{searchTerm.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PageVisitsTable pageVisits={pageVisits} style={{ marginTop: "20px" }} />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson,
    filters: state.filterContext.filters
  };
};

export default connect(mapStateToProps)(Website);
