import React from "react";

import tinycolor from "tinycolor2";
import ProviderIcon from "./ProviderIcon";
import IconButton from "../UIComponents/Button/IconButton";

export const googleRed = "#DB4437";
export const facebookBlue = "#3b5998";
export const twitterBlue = "#00aced";
export const linkedInBlue = "#0072b1";

const lightenFactor = 20; // Lighten light colors by 20%

export const providerColor = provider => {
  if (provider === "GoogleAds") {
    return googleRed;
  } else if (provider === "Facebook") {
    return facebookBlue;
  } else if (provider === "TwitterAds") {
    return twitterBlue;
  } else if (provider === "LinkedIn") {
    return linkedInBlue;
  } else {
    return "#777777";
  }
};

export const ProviderButton = props => {
  return (
    <IconButton
      {...props}
      style={{
        backgroundColor: providerColor(props.provider),
        color: "#FFF",
        ...props.style
      }}
      icon={
        <ProviderIcon
          providerName={props.provider}
          style={{ color: "white" }}
        ></ProviderIcon>
      }
    >
      {props.children}
    </IconButton>
  );
};

export const lightProviderColor = provider => {
  return tinycolor(providerColor(provider))
    .lighten(lightenFactor)
    .toString();
};
