import React, { Fragment } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import ReachTextField from "../../UIComponents/ReachTextField";

function UserUpdateForm(props) {
  const maybeUser = props.user;
  const onSubmit = props.onSubmit;
  const registration = !!props.registration;

  const initialValues = !!maybeUser
    ? maybeUser
    : {
        ...(registration ? { password: "" } : {}),
        name: "",
        email: "",
        profileImage: ""
      };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Required"),
        ...(registration
          ? { password: Yup.string().required("Required") }
          : {}),
        email: Yup.string()
          .email("Must be a valid email")
          .required("Required"),
        profileImage: Yup.string().url("Must be a valid url")
      })}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        } = props;

        console.log(errors);
        console.log(touched);

        return (
          <form onSubmit={handleSubmit}>
            <ReachTextField
              id="name"
              value={values.name}
              label="Name"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.name && touched.name}
              helperText={errors.name}
            />
            <br />
            <br />

            <ReachTextField
              id="email"
              value={values.email}
              label="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.email && touched.email}
              helperText={errors.email}
            />

            <br />
            <br />

            {registration && (
              <Fragment>
                <ReachTextField
                  id="password"
                  type="password"
                  value={values.password}
                  label="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.password && touched.password}
                  helperText={errors.password}
                />
                <br />
                <br />
              </Fragment>
            )}
            <ReachTextField
              id="profileImage"
              value={values.profileImage}
              label="Profile Image"
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.profileImage && touched.profileImage}
              helperText={errors.profileImage}
            />

            <br />
            <br />

            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </form>
        );
      }}
    </Formik>
  );
}

export default UserUpdateForm;
