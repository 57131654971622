import React, { Fragment } from "react";
import UserUpdateForm from "./UserUpdateForm";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import ReachPaper from "../../UIComponents/ReachPaper";

function UserUpdate(props) {
  const history = useHistory();

  const { userId } = props.match.params;
  const [user, setUser] = useState(null);

  const updateUser = user => {
    props.putJson("/v7/user/" + user.id, user).then(response => {
      if (response.status === 200) {
        history.push("/users");
      } else {
        console.warn("Failure");
      }
    });
  };

  useEffect(() => {
    props.getJson("/v7/user/" + userId).then(response => {
      if (response.status === 200) {
        setUser(response.json);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <ReachPaper>
        <h1>Update User</h1>
        {!!user ? (
          <UserUpdateForm user={user} onSubmit={updateUser} />
        ) : (
          <span>Loading</span>
        )}
      </ReachPaper>
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson,
    putJson: state.io.http.putJson
  };
};

export default connect(mapStateToProps)(UserUpdate);
