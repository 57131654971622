import { stringify } from "qs";

const serializeDate = function(d) {
  return d.getTime();
};

const reachStringify = queryStringParameters => {
  return stringify(queryStringParameters, {
    serializeDate,
    arrayFormat: "repeat"
  });
};

export default reachStringify;
