import React, { Fragment, useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import ReachPaper from "../UIComponents/ReachPaper";
import StepLabel from "@material-ui/core/StepLabel";
import { Link } from "react-router-dom";
import { prettyDateTime } from "../../util/prettyDate";

function EventController(props) {
  const [events, setEvents] = useState([]);

  const { websiteId } = props.match.params;

  useEffect(() => {
    console.log("init events");
    const eventSource = new EventSource(
      "https://api.choosereach.com/v7/events/stream/" + websiteId
    );

    eventSource.onmessage = function(e) {
      console.log(e.data);
      const event = JSON.parse(e.data);
      const newEvents = [event, ...events];
      console.log("new events: " + newEvents);
      setEvents(events => [event, ...events].splice(0, 50));
    };

    eventSource.addEventListener(
      "error",
      function(e) {
        if (e.eventPhase === EventSource.CLOSED) {
          // Connection was closed.
          console.log("Connection was closed on error: " + e);
        } else {
          console.log("Error occurred while streaming: " + e);
        }
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h1>Events</h1>
      {events.map(event => {
        return (
          <ReachPaper style={{ marginBottom: "5px" }}>
            <b>{event.type}</b>
            <br />
            {event.details.map(detail => {
              return (
                <Fragment>
                  <b>{detail.name}:</b> {detail.value}
                  <br />
                </Fragment>
              );
            })}
            Page Title{" "}
            <a href={event.context.pageUrl}>{event.context.pageTitle}</a>
            <br />
            Page Url: {event.context.pageUrl}
            <br />
            {prettyDateTime(event.timestamp)}
            <br />
          </ReachPaper>
        );
      })}
    </div>
  );
}

export default EventController;
