const stringToHash = string => {
  var hash = 0;

  if (string.length === 0) return hash;

  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return hash;
};

export const objectToHash = object => stringToHash(JSON.stringify(object));

export default stringToHash;
