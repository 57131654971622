export const logoForHost = () => {
  if (
    window.location.hostname === "dashboard.doctorsuits.com" ||
    window.location.hostname === "app.lexreach.com"
  ) {
    return process.env.PUBLIC_URL + "/logo/logo-lexreach.svg";
  } else if (window.location.hostname === "app.livetogivegroup.com") {
    return process.env.PUBLIC_URL + "/logo/logo-livetogive.webp";
  } else if (window.location.hostname === "app.toursengine.com") {
    return process.env.PUBLIC_URL + "/logo/logo-toursengine.png";
  }

  return process.env.PUBLIC_URL + "/logo/logo-reach.jpg";
};
