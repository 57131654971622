import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { setUserSessionInfo } from "../../state/actionCreators/counter";
import LoginForm from "./LoginForm";
import "./LoginForm.css";
import withStyles from "@material-ui/core/styles/withStyles";
import Footer from "../Footer/Footer";

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit * 2
  },
  padding: {
    padding: theme.spacing.unit
  }
});

class Login extends Component {
  state = {
    errorMessage: "asdf"
  };

  render() {
    const { authErrorMessage } = this.props;
    return (
      <Fragment>
        <LoginForm
          onSubmitHandler={this.login.bind(this)}
          errorMessage={authErrorMessage}
        />
        <Footer />
      </Fragment>
    );
  }

  login({ email, password }) {
    const { setUserSessionInfo, postJson } = this.props;
    const setState = this.setState.bind(this);

    postJson("/v7/login", { email, password }).then(response => {
      if (response.status === 200) {
        localStorage.clear();
        setState({
          errorMessage: undefined
        });
        localStorage.setItem(
          "reach.userSessionInfo",
          JSON.stringify(response.json.UserSession)
        );
        setUserSessionInfo(response.json.UserSession);
      } else if (response.status === 401 && response.json.error !== undefined) {
        // Unauthorized
        setState({
          errorMessage: response.json.error
        });
      }
    });
  }
}

const mapDispatchToProps = { setUserSessionInfo };

const mapStateToProps = state => {
  return {
    authErrorMessage: state.auth.errorMessage,
    postJson: state.io.http.postJson
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Login));
