import "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginTop: theme.spacing(2),
    marginLeft: 0,
    marginRight: theme.spacing(1),
    width: 200
  }
}));

export default function ReachDateRangePickerNew(props) {
  const classes = useStyles();

  const handleStartDateChange = dateString => {
    props.selectDateRange(moment(dateString).toDate(), props.endDate);
  };

  const handleEndDateChange = dateString => {
    props.selectDateRange(props.startDate, moment(dateString).toDate());
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        className={classes.textField}
        margin="normal"
        label="Start Date"
        value={moment(props.startDate).format("YYYY-MM-DD")}
        onChange={handleStartDateChange}
        KeyboardButtonProps={{
          "aria-label": "change start date"
        }}
      />
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        className={classes.textField}
        format="MM/dd/yyyy"
        margin="normal"
        label="End Date"
        value={moment(props.endDate).format("YYYY-MM-DD")}
        onChange={handleEndDateChange}
        KeyboardButtonProps={{
          "aria-label": "change end date"
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
