import React, { Component } from "react";

import { unsetUser } from "../../state/actionCreators/counter";
import { connect } from "react-redux";
import reachStringify from "../../util/stringify";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppTwoToneIcon from "@material-ui/icons/ExitToAppTwoTone";

class LogoutLink extends Component {
  render() {
    return (
      <ListItem button onClick={this.logout.bind(this)}>
        <ListItemIcon>
          <ExitToAppTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary={"Log Out"} />
      </ListItem>
    );
  }

  logout() {
    // TODO - hit an api endpoint to invalidate token]
    console.warn("Invalidate token: " + this.props.userSessionInfo.userId);
    this.props.post(
      "/v7/logout?" +
        reachStringify({
          token: this.props.userSessionInfo.userId
        })
    );
    this.props.unsetUser("Logged Out.");
    localStorage.clear();
  }
}

const mapStateToProps = state => {
  return {
    userSessionInfo: state.auth.userSessionInfo,
    post: state.io.http.post
  };
};

const mapDispatchToProps = { unsetUser };

export default connect(mapStateToProps, mapDispatchToProps)(LogoutLink);
