export const requireRole = (userSessionInfo, roleName) => {
  const roles = !userSessionInfo ? [] : userSessionInfo.roles || [];
  return roles.includes("Super User") || roles.includes(roleName);
};

export const requireAtLeastOneRole = (userSessionInfo, roleNames) => {
  const roles = !userSessionInfo ? [] : userSessionInfo.roles || [];

  return (
    roles.includes("Super User") ||
    roleNames.findIndex(roleName => {
      return roles.includes(roleName);
    }) !== -1
  );
};
