import moment from "moment";

export const isToday = someDate => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export const prettyDate = timestamp => {
  const date = moment.utc(timestamp).toDate();
  return isToday(date) ? "Today" : date.toLocaleDateString();
};

export const prettyTime = timestamp => {
  const date = moment.utc(timestamp).toDate();
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

export const prettyDateTime = timestamp => {
  const date = moment.utc(timestamp).toDate();
  const prettyDate = isToday(date) ? "Today" : date.toLocaleDateString();
  const prettyTime = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit"
  });

  return prettyDate + " " + prettyTime;
};
