import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import SectionHeader from "../UIComponents/Typography/SectionHeader";
import ProviderIcon from "../Provider/ProviderIcon";
import LeadsTable from "../CRM/LeadsTable";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import ReachPaper from "../UIComponents/ReachPaper";

class ProviderAdCampaign extends Component {
  state = {
    providerAdCampaign: undefined,
    leads: []
  };

  componentDidMount() {
    const { providerAdCampaignId } = this.props.match.params;

    this.props
      .getJson("/v7/providerAdCampaign/" + providerAdCampaignId)
      .then(response => {
        this.setState({
          providerAdCampaign: response.json.providerAdCampaign,
          leads: response.json.leads
        });
      });
  }

  render() {
    const { providerAdCampaign, leads } = this.state;
    const { filters } = this.props;

    return (
      <Fragment>
        {!!providerAdCampaign && (
          <Fragment>
            <ReachPaper
              elevation={2}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <SectionHeader>
                <ProviderIcon
                  providerName={providerAdCampaign.provider}
                  style={{
                    position: "relative",
                    top: "7px",
                    left: "10px",
                    marginRight: "10px"
                  }}
                />{" "}
                {providerAdCampaign.name}
              </SectionHeader>
            </ReachPaper>
            <LeadsTable
              leads={leads}
              filters={filters}
              style={{ marginTop: "20px" }}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson,
    filters: state.filterContext.filters
  };
};

export default connect(mapStateToProps)(ProviderAdCampaign);
