function isMobileDevice() {
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  );
}

function navBar(
  state = {
    collapsed: isMobileDevice()
  },
  action
) {
  switch (action.type) {
    case "TOGGLE_NAVBAR":
      return { ...state, collapsed: !state.collapsed };
    case "EXPAND_NAVBAR":
      return { ...state, collapsed: false };
    case "COLLAPSE_NAVBAR":
      return { ...state, collapsed: true };
    default:
      return state;
  }
}

export default navBar;
