import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { setUserSessionInfo } from "../../state/actionCreators/counter";
import WebIcon from "@material-ui/icons/Web";
import IconButton from "../UIComponents/Button/IconButton";
import SyntaxHighlighter from "react-syntax-highlighter";
import ReachPaper from "../UIComponents/ReachPaper";

function BusinessWebsites(props) {
  const { businessId } = props;

  const [websites, setWebsites] = useState([]);

  const refreshWebsites = () => {
    props.getJson("/v7/websites?businessId=" + businessId).then(response => {
      setWebsites(response.json.websites);
    });
  };

  useEffect(refreshWebsites, []);

  return (
    <Fragment>
      {websites.map((website, index) => {
        const websiteSnippetHref =
          "https://api.choosereach.com/" + website.id + "/reach.js";
        const websiteSnippet =
          "<script type='text/javascript' src='" +
          websiteSnippetHref +
          "'></script>";

        return (
          <ReachPaper style={{ marginTop: "20px" }} key={index}>
            <h1>Website - {website.name}</h1>

            <SyntaxHighlighter language="html">
              {websiteSnippet}
            </SyntaxHighlighter>
          </ReachPaper>
        );
      })}
      <IconButton
        variant="contained"
        href={"/client/" + businessId + "/website/create"}
        type="submit"
        style={{ "margin-top": "10px" }}
        icon={<WebIcon />}
      >
        Add Website
      </IconButton>
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    userSessionInfo: state.auth.userSessionInfo,
    postJson: state.io.http.postJson,
    getJson: state.io.http.getJson
  };
};

const mapDispatchToProps = { setUserSessionInfo };

export default connect(mapStateToProps, mapDispatchToProps)(BusinessWebsites);
