import React from "react";

import { Link } from "react-router-dom";
import debounce from "../../util/debounce";
import { connect } from "react-redux";

class ListListings extends React.Component {
  state = {
    listings: [],
    searchQuery: ""
  };

  render() {
    return (
      <div>
        <h1>Listings</h1>
        <div>
          <label>
            <input
              type="text"
              onChange={this.updateSearch.bind(this)}
              placeholder="Search Listings"
            />
          </label>
        </div>
        {this.state.listings.map(listing => {
          return (
            <div key={listing.id}>
              <h3>{listing.name}</h3>
              <div>{listing.addressLine1}</div>
              <div>{listing.phone}</div>
              <div>{listing.categories}</div>
              <div>
                <Link to={"/edit/listing/" + listing.id}>Edit</Link>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  componentDidMount() {
    ListListings.listListings("").then(response => {
      this.setState({
        listings: response.json.listings
      });
    });
  }

  updateSearch(e) {
    e.persist();

    if (!this.debouncedSearch) {
      this.debouncedSearch = debounce(() => {
        ListListings.listListings(e.target.value).then(response => {
          this.setState({
            listings: response.json.listings
          });
        });
      }, 200);
    }
    this.debouncedSearch();
  }

  listListings(searchQuery) {
    return this.props.getJson(
      "/v7/listings" + (searchQuery !== "" ? "?searchQuery=" + searchQuery : "")
    );
  }
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson
  };
};

export default connect(mapStateToProps)(ListListings);
