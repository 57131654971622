import React, { Fragment, useEffect, useState } from "react";

// Import React Table
import "react-table/react-table.css";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import SectionHeader from "../UIComponents/Typography/SectionHeader";
import tableIcons from "../UIComponents/Table/tableIcons";
import IconButton from "../UIComponents/Button/IconButton";
import BusinessIcon from "@material-ui/icons/Business";
import ReachPaper from "../UIComponents/ReachPaper";

const columns = [
  {
    title: "",
    render: agency => (
      <Link to={"/agency/update/" + agency.id}>{agency.name}</Link>
    ),
    cellStyle: {
      paddingRight: 0
    },
    headerStyle: {
      paddingRight: 0
    }
  }
];

function Agencies(props) {
  const [agencies, setAgencies] = useState([]);

  useEffect(() => {
    props.getJson("/v7/agencies").then(response => {
      response.status === 200 && setAgencies(response.json.agencies);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <h1>Agencies</h1>
      <IconButton
        variant="contained"
        href={"/agency/create"}
        type="submit"
        style={{ "margin-bottom": "10px" }}
        icon={<BusinessIcon />}
      >
        Add Agency
      </IconButton>
      <MaterialTable
        components={{
          Container: props => <ReachPaper {...props} />
        }}
        columns={columns}
        data={agencies}
        search={false}
        options={{
          pageSize: agencies.length === 0 ? 10 : Math.min(agencies.length, 10),
          pageSizeOptions: [50, 100, 200]
        }}
        classes={{ toolbar: { title: "myTitleClass" } }}
        title={<SectionHeader>Agencies</SectionHeader>}
        icons={tableIcons}
      />
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson
  };
};

export default connect(mapStateToProps)(Agencies);
