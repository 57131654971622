import { unsetUser } from "../state/actionCreators/counter";

import config from "../config/index";

export default store => {
  const handleJsonResponse = response => {
    return response.json().then(json => {
      // If unauthorized, redirect to login page
      if (response.status === 401) {
        store.dispatch(unsetUser(json.error));

        return {
          status: response.status,
          statusText: response.statusText,
          json: {},
          headers: response.headers
        };
      }

      return {
        status: response.status,
        statusText: response.statusText,
        json: json,
        headers: response.headers
      };
    });
  };

  const getAuthToken = () => {
    const maybeAuthTokenStorage = localStorage.getItem("reach.userSessionInfo");

    if (
      maybeAuthTokenStorage !== null &&
      maybeAuthTokenStorage !== "undefined"
    ) {
      const maybeAuthToken = JSON.parse(maybeAuthTokenStorage);

      return { Authorization: "Bearer " + maybeAuthToken["jwt"] };
    } else {
      console.warn("Did not find auth token.");

      return {};
    }
  };

  return {
    postJson: (url, body) => {
      const headers = Object.assign(
        {
          "Content-Type": "application/json"
        },
        getAuthToken()
      );

      return fetch(config.apiUrl + url, {
        method: "POST",
        headers,
        body: JSON.stringify(body)
      }).then(handleJsonResponse);
    },

    putJson: (url, body) => {
      const headers = Object.assign(
        {
          "Content-Type": "application/json"
        },
        getAuthToken()
      );

      return fetch(config.apiUrl + url, {
        method: "PUT",
        headers,
        body: JSON.stringify(body)
      }).then(handleJsonResponse);
    },

    post: (url, body) => {
      const headers = getAuthToken();

      return fetch(config.apiUrl + url, {
        method: "POST",
        headers,
        body: body
      });
    },

    delete: (url, body) => {
      const headers = getAuthToken();

      return fetch(config.apiUrl + url, {
        method: "DELETE",
        headers,
        body: body
      });
    },

    getJson: url => {
      const headers = Object.assign(
        {
          "Content-Type": "application/json"
        },
        getAuthToken()
      );

      return fetch(config.apiUrl + url, {
        method: "GET",
        headers
      }).then(handleJsonResponse);
    },

    get: url => {
      const headers = Object.assign(getAuthToken());

      return fetch(config.apiUrl + url, {
        method: "GET",
        headers
      });
    }
  };
};
