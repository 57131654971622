import React from "react";

import jsxToString from "jsx-to-string";
import SyntaxHighlighter from "react-syntax-highlighter";
import Paper from "@material-ui/core/Paper";
import ReachPaper from "../UIComponents/ReachPaper";
import SectionHeader from "../UIComponents/Typography/SectionHeader";

const elementToString = element => {
  return [element, jsxToString(element)];
};

function WidgetGuideSection(props) {
  return (
    <ReachPaper style={{ marginTop: "20px" }}>
      <SectionHeader>{props.title}</SectionHeader>
      <div>{props.children[0]}</div>
      <SyntaxHighlighter language="html">{props.children[1]}</SyntaxHighlighter>
    </ReachPaper>
  );
}

function WidgetGuide(props) {
  return (
    <div>
      <h1>Documentation</h1>
      <WidgetGuideSection title="Lead Generation Form">
        {elementToString(
          <form data-reach-form="Beacon">
            Email: <input type="email" name="email" data-reach-input="email" />
            <br />
            Phone: <input type="text" name="phone" data-reach-input="phone" />
            <br />
            Name: <input type="text" name="name" data-reach-input="name" />
            <button>Submit</button>
          </form>
        )}
      </WidgetGuideSection>
      <WidgetGuideSection title="Sweepstakes Form">
        {elementToString(
          <div>
            <form data-reach-sweep="Facebook">
              <button>Share Via Facebook</button>
              <input
                placeholder="Your Facebook Post"
                type="text"
                name="name"
                data-reach-url="email"
              />
              <button>Submit</button>
            </form>
            <form data-reach-sweep="Email">
              <button>Share Via Email</button>
              <input
                placeholder="Your Email"
                type="text"
                name="name"
                data-reach-input="email"
              />
              <button>Submit</button>
            </form>
          </div>
        )}
      </WidgetGuideSection>
      <WidgetGuideSection title="Feedback">
        {elementToString(
          <div
            data-reach-feedback="Requirements Table"
            data-reach-feedback-out-of="1"
            className="feedback"
          >
            <div>Is this table helpful?</div>
            <button data-reach-feedback-rating="1">Yes</button> /{" "}
            <button data-reach-feedback-rating="0">No</button>
            <form data-reach-feedback-form="Requirements Table">
              <input
                type="email"
                data-reach-input="email"
                placeholder="Email"
              />
              <br />
              <textarea placeholder="Tell us why" data-reach-input="message" />
              <br />
              <input type="submit" value="Submit" />
            </form>
          </div>
        )}
      </WidgetGuideSection>
      <WidgetGuideSection title="Review">
        {elementToString(
          <form data-reach-review="Company">
            <input
              type="radio"
              value="1"
              name="company-rating"
              data-reach-rating-out-of="5"
              data-reach-rating-group="Company"
            />
            <input
              type="radio"
              value="2"
              name="company-rating"
              data-reach-rating-out-of="5"
              data-reach-rating-group="Company"
            />
            <input
              type="radio"
              value="3"
              name="company-rating"
              data-reach-rating-out-of="5"
              data-reach-rating-group="Company"
            />
            <input
              type="radio"
              value="4"
              name="company-rating"
              data-reach-rating-out-of="5"
              data-reach-rating-group="Company"
            />
            <input
              type="radio"
              value="5"
              name="company-rating"
              data-reach-rating-out-of="5"
              data-reach-rating-group="Company"
            />
            <br />
            <input
              type="email"
              data-reach-input="email"
              placeholder="Email"
              required
            />
            <br />
            <textarea
              placeholder="Leave a review (optional)"
              maxLength="5000"
              data-reach-input="message"
            />
            <br />
            <input type="submit" value="Submit Review" />
          </form>
        )}
      </WidgetGuideSection>
      <WidgetGuideSection title="Trending">
        {elementToString(
          <div
            data-reach-trending-directory="www.royaltyexoticcars.com/car-rentals"
            data-reach-trending-slug="2016-corvette-z06-red-convertible"
          >
            Trending Now
          </div>
        )}
      </WidgetGuideSection>
      <WidgetGuideSection title="Demand">
        {elementToString(
          <div
            data-reach-notification="Demand"
            data-reach-trending-directory="www.royaltyexoticcars.com/car-rentals"
            data-reach-trending-slug="2016-corvette-z06-red-convertible"
          >
            <span
              data-reach-trending-period="day"
              data-reach-require-in-demand=""
              data-reach-trending-threshold="4"
            >
              4
            </span>{" "}
            visitors today.
          </div>
        )}
      </WidgetGuideSection>
    </div>
  );
}

export default WidgetGuide;
