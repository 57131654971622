import React, { Fragment, useEffect, useState } from "react";

// Import React Table
import "react-table/react-table.css";

import { connect } from "react-redux";
import Person from "@material-ui/icons/Person";
import IconButton from "../../UIComponents/Button/IconButton";
import UsersTable from "./UsersTable";

function Users(props) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    props.getJson("/v7/users").then(response => {
      response.status === 200 && setUsers(response.json.users);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <h1>Users</h1>
      <IconButton
        variant="contained"
        href={"/user/create"}
        type="submit"
        style={{ "margin-bottom": "10px" }}
        icon={<Person />}
      >
        Add User
      </IconButton>
      {!!users && <UsersTable users={users} />}
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson,
    postJson: state.io.http.postJson,
    userSessionInfo: state.auth.userSessionInfo
  };
};

export default connect(mapStateToProps)(Users);
