import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { prettyDate } from "../../util/prettyDate";
import LeadDelivery from "./LeadDelivery";
import LeadNotes from "./LeadNotes";
import { Paper } from "@material-ui/core";
import SectionHeader from "../UIComponents/Typography/SectionHeader";
import ProviderIcon from "../Provider/ProviderIcon";
import LeadsTable from "./LeadsTable";
import EventsTimeline from "../EventTracking/EventsTimeline";
import PurchasesTable from "../Purchases/PurchasesTable";
import ReachPaper from "../UIComponents/ReachPaper";

class Lead extends React.Component {
  state = {
    lead: undefined,
    providerAdCampaign: undefined,
    deliveries: undefined,
    events: undefined,
    call: undefined,
    leadNotes: undefined
  };

  componentDidMount() {
    const { leadId } = this.props.match.params;
    this.props.getJson("/v7/lead/" + leadId).then(response => {
      this.setState({
        lead: response.json.lead,
        providerAdCampaign: response.json.providerAdCampaign,
        deliveries: response.json.deliveries,
        events: response.json.events,
        call: response.json.call,
        leadNotes: response.json.leadNotes,
        duplicateLeads: response.json.duplicateLeads,
        purchaseItems: response.json.purchaseItems
      });
    });
  }

  render() {
    const {
      lead,
      events,
      providerAdCampaign,
      deliveries,
      call,
      duplicateLeads,
      leadNotes,
      purchaseItems
    } = this.state;

    const leadHasReferrerData =
      lead === undefined
        ? false
        : !!lead.utm.source ||
          !!lead.utm.campaign ||
          !!lead.utm.term ||
          !!lead.utm.medium ||
          !!lead.context.referrer;

    return (
      <Fragment>
        {lead !== undefined && (
          <Fragment>
            <ReachPaper>
              <SectionHeader>Lead Details</SectionHeader>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>Timestamp</b>
                    </td>
                    <td>{prettyDate(lead.timestamp)}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Opened</b>
                    </td>
                    <td>{lead.opened && prettyDate(lead.opened)}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Name</b>
                    </td>
                    <td>{lead.details.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Phone</b>
                    </td>
                    <td>
                      {!!lead.details.phone && (
                        <a href={"tel:" + lead.details.phone}>
                          {lead.details.phone}
                        </a>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Email</b>
                    </td>
                    <td>
                      {!!lead.details.email && (
                        <a href={"mailto:" + lead.details.email}>
                          {lead.details.email}
                        </a>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Form</b>
                    </td>
                    <td>{lead.context.formName}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Message</b>
                    </td>
                    <td>{lead.details.message}</td>
                  </tr>
                  {(lead.details.additionalDetails || []).map(detail => {
                    return (
                      <tr>
                        <td>
                          <b>{detail.name}</b>
                        </td>
                        <td>{detail.value}</td>
                      </tr>
                    );
                  })}
                  {!!lead.crm.qualified && (
                    <tr>
                      <td>
                        <b>Qualified</b>
                      </td>
                      <td>{prettyDate(lead.crm.qualified)}</td>
                    </tr>
                  )}
                  {!!lead.crm.contacted && (
                    <tr>
                      <td>
                        <b>Contacted</b>
                      </td>
                      <td>{prettyDate(lead.crm.contacted)}</td>
                    </tr>
                  )}
                  {!!lead.crm.presented && (
                    <tr>
                      <td>
                        <b>Presented</b>
                      </td>
                      <td>{prettyDate(lead.crm.presented)}</td>
                    </tr>
                  )}
                  {!!lead.crm.becameClient && (
                    <tr>
                      <td>
                        <b>Became a client</b>
                      </td>
                      <td>{prettyDate(lead.crm.becameClient)}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </ReachPaper>
            {purchaseItems && purchaseItems.length > 0 && (
              <Fragment>
                <div style={{ marginTop: "20px" }} />
                <PurchasesTable
                  purchaseItems={purchaseItems}
                  options={{
                    paging: false,
                    pageSize: purchaseItems.length,
                    search: false
                  }}
                />
              </Fragment>
            )}
            {call && (
              <ReachPaper
                elevation={2}
                style={{ padding: "20px", marginTop: "20px" }}
              >
                <SectionHeader>Call</SectionHeader>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <b>From</b>
                      </td>
                      <td>{call.from}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>To</b>
                      </td>
                      <td>{call.to}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Answered</b>
                      </td>
                      <td>{call.answered ? "yes" : "no"}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Duration</b>
                      </td>
                      <td>
                        {call.recordingDuration &&
                          call.recordingDuration + " seconds"}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                {call.recordingUrl && (
                  <audio controls>
                    <source src={call.recordingUrl} type="audio/mpeg" />
                    Your browser does not support audio playback.
                  </audio>
                )}
              </ReachPaper>
            )}
            {leadHasReferrerData && (
              <ReachPaper style={{ marginTop: "20px" }}>
                <SectionHeader>Referrer</SectionHeader>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <b>Referrer</b>
                      </td>
                      <td>{lead.context.referrer}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>UTM Source</b>
                      </td>
                      <td>{lead.utm.source}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>UTM Campaign</b>
                      </td>
                      <td>{lead.utm.campaign}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>UTM Term</b>
                      </td>
                      <td>{lead.utm.term}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>UTM Medium</b>
                      </td>
                      <td>{lead.utm.medium}</td>
                    </tr>
                  </tbody>
                </table>
              </ReachPaper>
            )}
            {!!providerAdCampaign && (
              <ReachPaper
                elevation={2}
                style={{ padding: "20px", marginTop: "20px" }}
              >
                <SectionHeader>Ad Campaign</SectionHeader>
                <ProviderIcon
                  providerName={providerAdCampaign.provider}
                  style={{
                    fontSize: "1.25rem"
                  }}
                />{" "}
                <Link to={"/providerAdCampaign/" + providerAdCampaign.id}>
                  {providerAdCampaign.name}
                </Link>
              </ReachPaper>
            )}
            {duplicateLeads.length > 0 && (
              <div style={{ marginTop: "20px" }}>
                <LeadsTable
                  leads={duplicateLeads}
                  filters={this.props.filters}
                  title="Duplicate Leads"
                />
              </div>
            )}
            <ReachPaper style={{ marginTop: "20px" }}>
              <SectionHeader>Notes</SectionHeader>
              <LeadNotes
                leadId={this.props.match.params.leadId}
                notes={leadNotes}
              />
            </ReachPaper>
            {deliveries && deliveries.length > 0 && (
              <ReachPaper style={{ marginTop: "20px" }}>
                <SectionHeader>Deliveries</SectionHeader>
                {deliveries.map((delivery, index) => {
                  return <LeadDelivery {...delivery} key={index} />;
                })}
              </ReachPaper>
            )}

            <ReachPaper style={{ marginTop: "20px" }}>
              <SectionHeader>Events</SectionHeader>
              {events && events.length > 0 && <EventsTimeline events={events} />}
            </ReachPaper>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    filters: state.filterContext.filters,
    getJson: state.io.http.getJson
  };
};

export default connect(mapStateToProps)(Lead);
