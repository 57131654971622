import React from "react";
import { Paper } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import IconButton from "../UIComponents/Button/IconButton";
import RateReviewIcon from "@material-ui/icons/RateReview";
import { ReachRating } from "../Feedback/FeedbackCard";
import ReachPaper from "../UIComponents/ReachPaper";

function ReviewCard(props) {
  const { review } = props;

  return (
    <ReachPaper style={{ marginBottom: "5px" }}>
      <b>
        {review.email || "Anonymous"} on {review.timestamp}:
      </b>
      {review.ratings.map(reviewRating => {
        return (
          <div>
            {reviewRating.name}:<br />
            {reviewRating.rating !== undefined && (
              <ReachRating rating={reviewRating} />
            )}
          </div>
        );
      })}
      <div>
        <i>{review.message}</i>
      </div>
      {props.showDetailLink && (
        <IconButton
          variant="contained"
          href={"/review/" + review.id}
          type="button"
          style={{ "margin-top": "10px" }}
          icon={<RateReviewIcon />}
        >
          Details
        </IconButton>
      )}
    </ReachPaper>
  );
}

export default ReviewCard;
