import React, { Fragment } from "react";
import AgencyUpdateForm from "./AgencyUpdateForm";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import UsersTable from "./Users/UsersTable";
import tableIcons from "../UIComponents/Table/tableIcons";
import SectionHeader from "../UIComponents/Typography/SectionHeader";
import UserAutocompleteSelector from "./Users/UserAutocompleteSelector";
import IconButton from "../UIComponents/Button/IconButton";
import AddBox from "@material-ui/icons/AddBox";
import ReachPaper from "../UIComponents/ReachPaper";

function AgencyUpdate(props) {
  const history = useHistory();

  const { agencyId } = props.match.params;
  const [agency, setAgency] = useState(null);
  const [agencyUsers, setAgencyUsers] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const [usersToAddToAgency, setUsersToAddToAgency] = useState(null);

  const updateAgency = agency => {
    props.putJson("/v7/agency/" + agency.id, agency).then(response => {
      if (response.status === 200) {
        history.push("/agencies");
      } else {
        console.warn("Failure");
      }
    });
  };

  const refreshAgencyUsers = () => {
    props.getJson("/v7/agency/" + agencyId + "/users").then(response => {
      if (response.status === 200) {
        const agencyUsers = response.json;
        setAgencyUsers(agencyUsers);
        refreshAllUsers(agencyUsers);
      }
    });
  };

  const refreshAllUsers = agencyUsers => {
    setAllUsers(null);
    props.getJson("/v7/users").then(response => {
      if (response.status === 200) {
        const allUsers = response.json.users;
        const agencyUsersIds = agencyUsers.map(user => user.id);
        const usersNotInAgency = allUsers.filter(
          user => !agencyUsersIds.includes(user.id)
        );

        setAllUsers(usersNotInAgency);
      }
    });
  };

  useEffect(() => {
    props.getJson("/v7/agency/" + agencyId).then(response => {
      if (response.status === 200) {
        setAgency(response.json);
      }
    });
    refreshAgencyUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeUserFromAgency = (agencyId, userId) =>
    props.delete("/v7/agency/" + agencyId + "/user/" + userId);

  const removeUserFromAgencyAction = user => ({
    icon: () => <tableIcons.Clear />,
    tooltip: "Remove User From Agency",
    onClick: (event, user) => {
      removeUserFromAgency(agencyId, user.id).then(refreshAgencyUsers);
    }
  });

  const grantSelectedUsersAccessToAgency = () => {
    if (usersToAddToAgency.length > 0) {
      const userIds = usersToAddToAgency.map(user => user.id);
      props
        .putJson("/v7/agency/" + agencyId + "/users", userIds)
        .then(refreshAgencyUsers);
    }
  };

  return (
    <Fragment>
      <ReachPaper style={{ marginBottom: "20px" }}>
        <h1>Update Agency</h1>
        {!!agency ? (
          <AgencyUpdateForm agency={agency} onSubmit={updateAgency} />
        ) : (
          <span>Loading</span>
        )}
      </ReachPaper>
      {!!agencyUsers && (
        <UsersTable
          users={agencyUsers}
          userActions={[removeUserFromAgencyAction]}
        />
      )}
      {allUsers && (
        <ReachPaper style={{ marginTop: "20px" }}>
          <SectionHeader>Add Users</SectionHeader>
          <UserAutocompleteSelector
            onChange={(event, selectedUsers) => {
              setUsersToAddToAgency(selectedUsers);
            }}
            users={allUsers}
          />
          <IconButton
            variant="contained"
            onClick={grantSelectedUsersAccessToAgency}
            type="submit"
            style={{ "margin-top": "10px" }}
            icon={<AddBox />}
          >
            Add Users
          </IconButton>
        </ReachPaper>
      )}
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson,
    putJson: state.io.http.putJson,
    delete: state.io.http.delete
  };
};

export default connect(mapStateToProps)(AgencyUpdate);
