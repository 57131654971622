/**
 * Used for filter data from different businesses across tabs
 */
import moment from "moment";
import { objectToHash } from "../../util/stringToHash";

function filterContext(
  state = {
    filterHash: "", // Used to see if filters have changed, updates when filters changed
    filters: {
      businessId: [],
      agencyId: [],
      startDate: moment()
        .subtract(2, "weeks")
        .toDate(),
      endDate: moment().toDate()
    },
    options: {
      businessId: [],
      agencyId: []
    }
  },
  action
) {
  switch (action.type) {
    case "FILTER_CONTEXT_LOADED": // Loaded from local storage
      return action.filterContext;
    case "UPDATE_FILTERS": // Set selected options
      const newFilters = {
        ...state.filters,
        ...action.filters
      };
      const newState = {
        ...state,
        filterHash: objectToHash(newFilters),
        filters: newFilters
      };
      localStorage.setItem("reach.filterContext", JSON.stringify(newState));

      return newState;
    case "UPDATE_OPTIONS": // Available options
      const newState2 = {
        ...state,
        options: { ...state.options, [action.optionAccessor]: action.options }
      };
      localStorage.setItem("reach.filterContext", JSON.stringify(newState2));

      return newState2;
    default:
      return state;
  }
}

export default filterContext;
