import React from "react";
import AgencyUpdateForm from "./AgencyUpdateForm";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import { setUserSessionInfo } from "../../state/actionCreators/counter";
import ReachPaper from "../UIComponents/ReachPaper";

function AgencyCreate(props) {
  const history = useHistory();

  const createAgency = agency => {
    props.postJson("/v7/agency", agency).then(response => {
      if (response.status === 200) {
        props.postJson("/v7/refreshToken").then(response => {
          // Refresh the users token so the agency is accessible
          localStorage.setItem(
            "reach.userSessionInfo",
            JSON.stringify(response.json.UserSession)
          );
          props.setUserSessionInfo(response.json.UserSession);
          history.push("/agencies");
        });
      } else {
        console.warn("Failure");
      }
    });
  };

  return (
    <ReachPaper elevation={2} style={{ padding: "20px" }}>
      <h1>Create Agency</h1>
      <AgencyUpdateForm onSubmit={createAgency} />
    </ReachPaper>
  );
}

const mapStateToProps = state => {
  return {
    userSessionInfo: state.auth.userSessionInfo,
    postJson: state.io.http.postJson
  };
};

const mapDispatchToProps = { setUserSessionInfo };

export default connect(mapStateToProps, mapDispatchToProps)(AgencyCreate);
