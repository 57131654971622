import React from "react";
import UserUpdateForm from "./UserUpdateForm";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import { setUserSessionInfo } from "../../../state/actionCreators/counter";
import ReachPaper from "../../UIComponents/ReachPaper";

function UserCreate(props) {
  const history = useHistory();

  const createUser = user => {
    props.postJson("/v7/register", user).then(response => {
      if (response.status === 200) {
        history.push("/users");
      } else {
        console.warn("Failure");
      }
    });
  };

  return (
    <ReachPaper>
      <h1>Register User</h1>
      <UserUpdateForm onSubmit={createUser} registration={true} />
    </ReachPaper>
  );
}

const mapStateToProps = state => {
  return {
    userSessionInfo: state.auth.userSessionInfo,
    postJson: state.io.http.postJson
  };
};

const mapDispatchToProps = { setUserSessionInfo };

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate);
