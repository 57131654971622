function auth(
  state = {
    userSessionInfo: undefined,
    errorMessage: undefined
  },
  action
) {
  switch (action.type) {
    case "SET_USER":
      return { ...state, userSessionInfo: action.userSessionInfo };
    case "UNSET_USER":
      return {
        ...state,
        userSessionInfo: undefined,
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
}

export default auth;
