import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import moment from "moment";

function LeadNotes({ leadId, notes, postJson }) {
  const [newNote, setNewNote] = useState("");
  const [internalNotes, setInternalNotes] = useState(notes);

  const postLeadNote = () => {
    const leadNote = {
      id: "",
      agencyId: "",
      businessId: "",
      leadId: leadId,
      userName: "",
      userId: "",
      date: "",
      note: newNote
    };
    postJson("/v7/leadNote", leadNote).then(response => {
      setInternalNotes(internalNotes.concat([response.json]));
    });
  };

  return (
    <div style={{ marginBottom: "15px" }}>
      {internalNotes.map((note, index) => {
        return (
          <div key={index}>
            <b>
              {note.userName} on{" "}
              {moment
                .utc(note.date)
                .local()
                .toDate()
                .toLocaleString()}
            </b>
            :
            <br />
            {note.note}
          </div>
        );
      })}
      <textarea
        value={newNote}
        placeholder={"Enter a new note"}
        onChange={event => setNewNote(event.target.value)}
        style={{ width: "100%" }}
        name="newNote"
      />
      <br />
      <Button onClick={postLeadNote}>Submit Note</Button>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    postJson: state.io.http.postJson
  };
};

export default connect(mapStateToProps)(LeadNotes);
