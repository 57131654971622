import React, { Fragment } from "react";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import MaterialTable from "material-table";
import ReachPaper from "../UIComponents/ReachPaper";
import SectionHeader from "../UIComponents/Typography/SectionHeader";
import tableIcons from "../UIComponents/Table/tableIcons";
import { Link } from "react-router-dom";
import { prettyDateTime } from "../../util/prettyDate";

const columns = [
  {
    title: "",
    render: eventTrackingSession => {
      const maybeMostRecentEvent = eventTrackingSession.events[0];

      return !!maybeMostRecentEvent ? (
        <Fragment>
          <Link to={"/eventSession/" + eventTrackingSession.anonymousUserId}>
            {maybeMostRecentEvent.type || ""}
          </Link>{" "}
          - ({eventTrackingSession.events.length} Events)
          <br />
          <Link
            className={"secondaryLink"}
            to={"/eventSession/" + eventTrackingSession.anonymousUserId}
          >
            <br />
            {maybeMostRecentEvent.details.map(detail => {
              return (
                <Fragment>
                  <b>{detail.name}:</b> {detail.value}
                  <br />
                </Fragment>
              );
            })}
            Page Title:{" "}
            <a href={maybeMostRecentEvent.context.pageUrl}>
              {maybeMostRecentEvent.context.pageTitle}
            </a>
            <br />
            Page Url: {maybeMostRecentEvent.context.pageUrl}
            <br />
            {prettyDateTime(maybeMostRecentEvent.timestamp)}
          </Link>
        </Fragment>
      ) : (
        ""
      );
    }
  }
];

function EventTrackingSessionsTable({ eventTrackingSessions }) {
  return (
    <MaterialTable
      components={{
        Container: props => <ReachPaper {...props} />
      }}
      columns={columns}
      data={eventTrackingSessions}
      options={{
        search: false,
        pageSize:
          eventTrackingSessions.length === 0
            ? 50
            : Math.min(eventTrackingSessions.length, 50),
        pageSizeOptions: [50, 100, 200]
      }}
      classes={{ toolbar: { title: "myTitleClass" } }}
      title={<SectionHeader>Most Recent Events</SectionHeader>}
      icons={tableIcons}
    />
  );
}

function EventTrackingSessions(props) {
  const { websiteId } = props.match.params;

  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    props.getJson("/v7/eventSessions/" + websiteId).then(response => {
      setSessions(response.json);
    });
  }, []);

  return <EventTrackingSessionsTable eventTrackingSessions={sessions} />;
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventTrackingSessions);
