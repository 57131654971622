import Button from "@material-ui/core/Button";
import React from "react";
import { providerColor } from "../../Provider/providerColors";

const IconButton = props => {
  return (
    <Button
      {...props}
      style={{
        textTransform: "none",
        backgroundColor: providerColor(props.provider),
        color: "#FFF",
        ...props.style
      }}
    >
      {props.icon}
      <span style={{ marginLeft: "15px" }}>{props.children}</span>
    </Button>
  );
};

export default IconButton;
