// Render Prop
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

const EditListingForm = ({ onSubmitHandler, initialListing }) => (
  <div>
    <Formik
      initialValues={{
        name: initialListing.name,
        addressLine1: initialListing.addressLine1,
        addressLine2: initialListing.addressLine2,
        phone: initialListing.phone
      }}
      validate={values => {
        let errors = {};
        // if (!values.email) {
        //     errors.email = 'Required';
        // } else if (
        //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        // ) {
        //     errors.email = 'Invalid email address';
        // }

        // TODO - client side validation

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          onSubmitHandler(values);
          setSubmitting(false);
        }, 400);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Field type="name" name="name" />
          <br />
          <ErrorMessage name="name" component="div" />
          <br />
          <Field type="addressLine1" name="addressLine1" />
          <br />
          <ErrorMessage name="addressLine1" component="div" />
          <br />
          <Field type="addressLine2" name="addressLine2" />
          <br />
          <ErrorMessage name="addressLine2" component="div" />
          <br />
          <Field type="phone" name="phone" />
          <br />
          <ErrorMessage name="phone" component="div" />
          <br />
          <button type="submit" disabled={isSubmitting}>
            Submit
          </button>
        </Form>
      )}
    </Formik>
  </div>
);

export default EditListingForm;
