import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import PurchasesTable from "./PurchasesTable";

function Purchases(props) {
  const [purchaseItems, setPurchaseItems] = useState([]);

  useEffect(() => {
    props.getJson("/v7/leadPurchaseItems").then(response => {
      response.status === 200 &&
        setPurchaseItems(response.json.leadPurchaseItems);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PurchasesTable purchaseItems={purchaseItems} />;
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson
  };
};

export default connect(mapStateToProps)(Purchases);
