import React, { Component, Fragment } from "react";

// Import React Table
import "react-table/react-table.css";

import "../CRM/CRMLeadsController.css";

import { prettyDate, prettyTime } from "../../util/prettyDate";
import MaterialTable from "material-table";

import ProviderIcon from "../Provider/ProviderIcon";
import SectionHeader from "../UIComponents/Typography/SectionHeader";
import tableIcons from "../UIComponents/Table/tableIcons";
import ReachPaper from "../UIComponents/ReachPaper";

const columns = filters => {
  return [
    {
      title: "Provider",
      render: pageVisit => <ProviderIcon providerName={pageVisit.utmSource} />
    },
    {
      title: "Page",
      render: pageVisit => {
        const split = pageVisit.url.split("?");
        const pathName = split[0] || "";
        const parameters = (split[1] || "")
          .split("&")
          .map((parameter, index) => {
            return (
              <Fragment>
                <br />
                {index === 0 ? "?" : "&"}
                {parameter}
              </Fragment>
            );
          });

        return (
          <div style={{ maxWidth: "450px", wordBreak: "break-all" }}>
            <a href={pageVisit.url} target="_blank">
              {pageVisit.title}
            </a>
            <br />
            <a className="secondaryLink" href={pageVisit.url} target="_blank">
              {pathName}
              {parameters}
            </a>
          </div>
        );
      }
    },
    {
      title: "Term",
      field: "utmTerm"
    },
    {
      id: "Date",
      title: "Date",
      render: pageVisit => (
        <Fragment>
          {prettyDate(pageVisit.timestamp)}
          <br />
          <span className="secondaryText">
            {prettyTime(pageVisit.timestamp)}
          </span>
        </Fragment>
      )
    }
  ];
};

const defaultTitle = "Page Visits";

class PageVisitsTable extends Component {
  render() {
    const { pageVisits, filters, style } = this.props;

    const title = this.props.title || defaultTitle;

    return (
      <div style={{ maxWidth: "100%", ...style }}>
        <MaterialTable
          components={{
            Container: props => <ReachPaper {...props} />
          }}
          columns={columns(filters)}
          data={pageVisits}
          search={false}
          options={{
            pageSize:
              pageVisits.length === 0 ? 10 : Math.min(pageVisits.length, 10),
            pageSizeOptions: [10, 50, 100]
          }}
          classes={{ toolbar: { title: "myTitleClass" } }}
          title={<SectionHeader>{title}</SectionHeader>}
          icons={tableIcons}
        />
      </div>
    );
  }
}

export default PageVisitsTable;
