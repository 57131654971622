import React, { Fragment, useEffect, useState } from "react";

// Import React Table
import "react-table/react-table.css";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import SectionHeader from "../UIComponents/Typography/SectionHeader";
import tableIcons from "../UIComponents/Table/tableIcons";
import IconButton from "../UIComponents/Button/IconButton";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import ReachPaper from "../UIComponents/ReachPaper";

const columns = [
  {
    title: "",
    render: client => (
      <Link to={"/client/update/" + client.id}>{client.name}</Link>
    ),
    cellStyle: {
      paddingRight: 0
    },
    headerStyle: {
      paddingRight: 0
    }
  }
];

function Businesses(props) {
  const [businesses, setBusinesses] = useState([]);

  useEffect(() => {
    props.getJson("/v7/businesses").then(response => {
      response.status === 200 && setBusinesses(response.json.businesses);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <h1>Clients</h1>
      <IconButton
        variant="contained"
        href={"/client/create"}
        type="submit"
        style={{ "margin-bottom": "10px" }}
        icon={<BusinessCenterIcon />}
      >
        Add Client
      </IconButton>
      <MaterialTable
        components={{
          Container: props => <ReachPaper {...props} />
        }}
        columns={columns}
        data={businesses}
        search={false}
        options={{
          pageSize:
            businesses.length === 0 ? 10 : Math.min(businesses.length, 10),
          pageSizeOptions: [50, 100, 200]
        }}
        classes={{ toolbar: { title: "myTitleClass" } }}
        title={<SectionHeader>Clients</SectionHeader>}
        icons={tableIcons}
      />
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    getJson: state.io.http.getJson
  };
};

export default connect(mapStateToProps)(Businesses);
